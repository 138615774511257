import { EventLogApiInterface, ProfessionalAccount, TeamApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { parseApiLog } from "../network/parsers/parseApiLog";
import { Log } from "../domain/log";
import { Dayjs } from "dayjs";

export interface EventLogApiLogQuerySearchParams {
  rootId?: string;
  emittedBy?: string;
  before?: Dayjs;
  after?: Dayjs;
}

export class EventLogQuery {
  constructor(
    private readonly store: AppStore,
    private readonly eventLogApi: EventLogApiInterface,
    private readonly teamApi: TeamApiInterface,
  ) {}

  async call(
    organizationId: string,
    teamId: string,
    searchParams: EventLogApiLogQuerySearchParams = {},
  ): Promise<Log[]> {
    try {
      const teamMembers = await this.teamApi.indexTeamMembers(organizationId, teamId);

      const result = await this.eventLogApi.listLogs(
        organizationId,
        teamId,
        searchParams.rootId,
        searchParams.emittedBy,
        searchParams.before?.toDate(),
        searchParams.after?.toDate(),
      );

      const logs = result.logs.map(parseApiLog);

      this.store.setState((state) => {
        const logsIds = logs.map((log) => log.id);
        const logsIdsStringify = JSON.stringify(logsIds);

        if (logsIdsStringify === state.lastEventLogsIdsStringify) return state;

        return {
          lastEventLogsIds: logsIds,
          lastEventLogsIdsStringify: logsIdsStringify,
          eventLogsById: {
            ...state.eventLogsById,
            ...logs.reduce(
              (acc, log) => {
                acc[log.id] = log;
                return acc;
              },
              {} as Record<string, Log>,
            ),
          },
          membersById: teamMembers.teamMembers.reduce(
            (acc, teamMember) => {
              acc[teamMember.member.id] = teamMember.member;
              return acc;
            },
            {} as Record<string, ProfessionalAccount>,
          ),
        };
      });

      return logs;
    } catch (error) {
      console.error("Failed to fetch event logs:", error);
      throw error;
    }
  }
}
