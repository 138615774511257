import { DocumentApiInterface } from "@syadem/kairos-pro-js";
import { AppStore } from "../store";

export class ProDocumentsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly documentApi: DocumentApiInterface,
  ) { }

  async call(healthRecordId: string) {
    const { documents } = await this.documentApi.indexDocuments(healthRecordId);

    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        state.healthRecords[healthRecordId].documents = documents;
      }
      return state;
    });
  }
}
