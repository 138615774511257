import Polyglot from "node-polyglot";
import { createContext } from "react";
import { LocaleString } from "../../translations";
import { fr } from "../../translations/fr";
import { frFR } from "@mui/x-date-pickers/locales";

export interface I18nInterface {
  setLocale: (locale: LocaleString) => void;
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string;
  getObject: (key: string) => string;
  locale: LocaleString;
}

type Path<T> = {
  [K in keyof T]: T[K] extends object ? `${string & K}` | `${string & K}.${Path<T[K]>}` : `${string & K}`;
}[keyof T];

export type I18nKey = Path<typeof fr>;

export const getLocaleText = (locale: string) => {
  switch (locale) {
    case "fr":
      return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    default:
      return undefined;
  }
};

// I18nContext value is set by the I18nProvider component.
export const I18nContext = createContext<I18nInterface>(null!);
