import { Professional, Team } from "@syadem/ariane-js";
import { localizedDate } from "./dayjs";
import { LocaleString } from "../translations";
import { I18nInterface } from "../ui/providers/I18nContext";
import { Certificate } from "../domain/VaccinationAct";

export function certificationDescription(
  certificators: (Professional | Team)[],
  certificate: Certificate,
  teamId: string | undefined,
  t: I18nInterface["t"],
  locale: LocaleString
): string {
  const certificator = certificators.find((c) => c.id === certificate.certificator.id);
  const dateLabel = localizedDate(certificate.certifiedOn, locale);
  let certificatorLabel = "";

  if (certificate.certificator.type === "professional") {
    if (certificator === undefined) {
      certificatorLabel = t("vaccines.aProfessional");
    } else {
      const professional = certificator as Professional;
      certificatorLabel = `${professional.first_name} ${professional.last_name}`;
    }
  } else if (certificate.certificator.type === "team") {
    if (certificator === undefined) {
      certificatorLabel = t("vaccines.aTeam");
    } else {
      const team = certificator as Team;

      if (teamId === team.id) {
        const teamMember = certificators.find((c) => c.id === certificate.certificator.teamMemberAccountId);
        const teamMemberLabel = teamMember ? `${(teamMember as Professional).first_name} ${(teamMember as Professional).last_name}` : undefined;
        certificatorLabel = t("vaccines.aTeamMember", { teamInfo: teamMemberLabel ? `"${team.name}" (${teamMemberLabel})` : team.name });
      } else {
        certificatorLabel = t("vaccines.aTeamMember", { teamInfo: `"${team.name}"` });
      }
    }
  } else {
    return("");
  }

  if (certificate.certificationMethod === "execution") {
    return(t("vaccines.validatedUponExecutionBy", { certificator: certificatorLabel, date: dateLabel }));
  } else if (certificate.certificationMethod === "proof") {
    return(t("vaccines.validatedUponProofBy", { certificator: certificatorLabel, date: dateLabel }));
  } else {
    return("");
  }
}
