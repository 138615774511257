import { Button } from "@mui/material";
import StyledDialog from "../../components/mui/StyledDialog";
import { useI18n } from "../../hooks/useI18n";

const AddSameVaccineDialog = ({
  open,
  onClose,
  createVaccine,
  isCreating = false,
}: {
  open: boolean;
  onClose: () => void;
  createVaccine: () => void;
  isCreating: boolean;
}) => {
  const { t } = useI18n();

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      title={t("vaccines.addSimilarVaccination.title")}
      dialogActions={
        <Button
          onClick={createVaccine}
          autoFocus
          variant="contained"
          disableElevation
          loading={isCreating}
          data-testid="addSimilar"
        >
          {t("common.cta.add")}
        </Button>
      }
    >
      {t("vaccines.addSimilarVaccination.details")}
    </StyledDialog>
  );
};

export default AddSameVaccineDialog;
