import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { AllSharingRequestsStatusEnum, SharingRequest } from "@syadem/kairos-pro-js";
import { capitalize, lowerCase } from "lodash-es";
import { ReactElement, useCallback } from "react";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import IconDoctor from "./icons/IconDoctor";
import { ListItemDeleteButton } from "./shared/ListItemDeleteButton";
import { formatDistanceToNow } from "../../utils/dayjs";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "../hooks/useAppContext";
import { DomainHealthRecord } from "../../domain/healthRecord";

interface SentSharingRequestListItemProps {
  sharingRequest: SharingRequest;
  healthRecord: DomainHealthRecord;
  refetch: () => void;
}

export function SentSharingRequestListItem(props: SentSharingRequestListItemProps) {
  const { sharingRequest, healthRecord, refetch } = props;
  const { t, locale } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDelete = useCallback(async () => {
    // TODO: Wrap this inside a service
    if (organizationId && teamId) {
      await apis.team.sharingRequestApi.deleteSharingRequest(
        organizationId,
        teamId,
        healthRecord.id,
        sharingRequest.id,
      );
    } else {
      await apis.pro.sharingRequestApi.deleteSharingRequest(healthRecord.id, sharingRequest.id);
    }
  }, [apis, organizationId, teamId, healthRecord, sharingRequest]);

  return (
    <ListItem
      sx={{
        px: { xs: 1, md: 3 },
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
      secondaryAction={
        sharingRequest.status === AllSharingRequestsStatusEnum.Pending ? (
          <ListItemDeleteButton
            deleteRecord={handleDelete}
            refreshCallback={refetch}
            title={t("common.cta.cancel")}
            modalTitle={t("health_records.cancel_invite")}
            modalContent={t("health_records.cancel_invite_validation")}
            icon={<ClearIcon />}
            label={t("common.cta.cancel")}
            variant="outlined"
            buttonWidth="110px"
          />
        ) : (
          <ListItemText
            sx={{
              marginY: "auto",
            }}
            secondary={t(`common.dates.processedAt`, {
              distance: formatDistanceToNow(locale, sharingRequest.processedAt as Date),
            })}
          />
        )
      }
    >
      <ListItemAvatar sx={{ minWidth: { xs: 5, md: 7 } }}>
        <Avatar
          sx={{
            background: theme.palette.primary[100],
            width: { xs: 32, md: 48 },
            height: { xs: 32, md: 48 },
            marginRight: { xs: 1, md: 2 },
          }}
        >
          <IconDoctor color={theme.palette.primary[500]} style={{ height: breakpointSm ? "20px" : "24px" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography fontWeight="500" color={theme.palette.neutral[600]} component="span">
            {`${capitalize(sharingRequest.toProIdentity?.firstName)} ${capitalize(
              sharingRequest.toProIdentity?.lastName,
            )}`}
          </Typography>
        }
        secondary={
          <>
            {iconForStatus(sharingRequest.status)}
            <Typography variant="body2" component="span">
              {t(`sharingRequestStatus.${lowerCase(sharingRequest.status)}`)}
            </Typography>
          </>
        }
        sx={{
          ".MuiListItemText-secondary": {
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    </ListItem>
  );
}

function iconForStatus(status: string): ReactElement | null {
  switch (status) {
    case "ACCEPTED":
      return <CheckCircleIcon color="success" sx={{ fontSize: "16px", my: 0, mr: 1 }} />;
    case "PENDING":
      return <WatchLaterIcon color="warning" sx={{ fontSize: "16px", my: 0, mr: 1 }} />;
    case "REJECTED":
      return <CancelIcon color="error" sx={{ fontSize: "16px", my: 0, mr: 1 }} />;
    default:
      return null;
  }
}
