import {
  CreateDocumentBodyDocument,
  CreateDocumentBodyDocumentCategoryEnum,
  DocumentApiInterface as ProDocumentApiInterface,
  Document as ProDocument,
} from "@syadem/kairos-pro-js";
import { DocumentApiInterface as TeamDocumentApiInterface, Document as TeamDocument } from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";
import { ProDocumentFileApiInterface } from "../network/apis/proDocumentFileApi";
import { TeamDocumentFileApiInterface } from "../network/apis/teamDocumentFileApi";

interface Payload {
  document: {
    name: string;
    category: CreateDocumentBodyDocumentCategoryEnum;
    date?: Date;
  };
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  attachments: File[];
  onProgressUpdate?: (progress: number) => void;
}

export class CreateDocumentAndAttachFilesService implements Service<Payload, ProDocument | TeamDocument> {
  constructor(
    private readonly proDocumentApi: ProDocumentApiInterface,
    private readonly teamDocumentApi: TeamDocumentApiInterface,
    private readonly proDocumentFileApi: ProDocumentFileApiInterface,
    private readonly teamDocumentFileApi: TeamDocumentFileApiInterface,
    private readonly store: AppStore,
  ) {}

  async call({
    document,
    healthRecordId,
    organizationId,
    teamId,
    attachments,
    onProgressUpdate,
  }: Payload): Promise<ActionResult<ProDocument | TeamDocument>> {
    let apiDocument: ProDocument | TeamDocument;

    if (organizationId && teamId) {
      apiDocument = await this.teamDocumentApi.createDocument(organizationId, teamId, healthRecordId, {
        document: document as CreateDocumentBodyDocument,
      });
    } else {
      apiDocument = await this.proDocumentApi.createDocument(healthRecordId, {
        document: document as CreateDocumentBodyDocument,
      });
    }

    if (attachments && attachments.length > 0) {
      if (organizationId && teamId) {
        apiDocument = await this.teamDocumentFileApi.attachFiles({
          organizationId,
          teamId,
          healthRecordId,
          documentId: apiDocument.id,
          attachments,
          onProgressUpdate,
        });
      } else {
        apiDocument = await this.proDocumentFileApi.attachFiles(
          healthRecordId,
          apiDocument.id,
          attachments,
          onProgressUpdate,
        );
      }
    }

    this.addDocumentInStore(healthRecordId, apiDocument);

    return {
      type: "success",
      value: apiDocument,
    };
  }

  private addDocumentInStore(healthRecordId: string, document: ProDocument | TeamDocument) {
    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        return {
          healthRecords: {
            ...state.healthRecords,
            [healthRecordId]: {
              ...state.healthRecords[healthRecordId],
              documents: [document, ...state.healthRecords[healthRecordId].documents],
            },
          },
        };
      }
      return state;
    });
  }
}
