import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Polyglot from "node-polyglot";
import { ReactNode, useEffect, useMemo } from "react";
import translations, { LocaleString } from "../../translations";
import { dayjs } from "../../utils/dayjs";
import usePersistedState from "../hooks/usePersistedState";
import { getLocaleText, I18nContext, I18nInterface } from "./I18nContext";

export const I18nProvider = ({ children, defaultLocale }: { children: ReactNode; defaultLocale: LocaleString }) => {
  const [currentLocale, setLocale] = usePersistedState<LocaleString>("proLocale", defaultLocale);

  useEffect(() => {
    dayjs.locale(currentLocale);
    if (typeof window !== "undefined") {
      document.documentElement.setAttribute("lang", currentLocale);
    }
  }, [currentLocale]);

  const polyglot = useMemo<Polyglot>(
    // Create a new instance on every change
    // This make sure we always consume the latest phrases and Polyglot context
    () =>
      new Polyglot({
        locale: currentLocale,
        phrases: translations[currentLocale],
      }),
    [currentLocale],
  );

  const polyglotContext: I18nInterface = useMemo(
    () => ({
      locale: polyglot.locale() as LocaleString,
      t: polyglot.t.bind(polyglot),
      setLocale,
      getObject: (key: string) => {
        return key
          .replace(/\[([^[\]]*)\]/g, ".$1.")
          .split(".")
          .filter((t) => t !== "")
          .reduce((prev: any, cur: string) => prev && prev[cur], translations[currentLocale]);
      },
    }),
    [polyglot, currentLocale, setLocale],
  );

  return (
    <I18nContext value={polyglotContext}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={dayjs.locale(currentLocale === "en-gb" ? "en" : currentLocale)}
        localeText={getLocaleText(currentLocale)}
      >
        {children}
      </LocalizationProvider>
    </I18nContext>
  );
};
