import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Link, Skeleton, Tab, Tabs, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Outlet, Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { calculateAge, utcToday } from "../../utils/ageCalculator";
import { getFullName } from "../../utils/healthRecord";
import { useFetchHealthRecord } from "../hooks/useFetchHealthRecord";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";
import { ResponseError } from "@syadem/kairos-pro-js";
import { localizedDate } from "../../utils/dayjs";
import { useHealthRecord } from "../../store";

export function HealthRecord() {
  const { t, locale } = useI18n();
  const { id } = useParams() as { id: string };
  const { error } = useFetchHealthRecord(id);
  const healthRecord = useHealthRecord(id);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (error instanceof Response && error.status === 404) ||
      (error instanceof ResponseError && error.response.status === 404)
    ) {
      navigate("/not_found");
    }
  }, [error, navigate]);

  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PageLayout
      title={
        breakpointMd ? (
          <Box sx={{ ml: 1 }}>
            <Link
              underline="none"
              color={theme.palette.primary.main}
              to={pathname.substring(0, pathname.lastIndexOf("/health-records") + 15)}
              component={RouterLink}
              sx={{
                "&:hover": {
                  color: theme.palette.primary[300],
                },
              }}
            >
              <Typography variant={breakpointSm ? "h6" : "h5"}>{t("patients.title")}</Typography>
            </Link>
            <Typography
              variant="h6"
              sx={{
                fontSize: 16,
                maxWidth: "240px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            >
              {healthRecord ? (
                getFullName({
                  firstNames: healthRecord.firstNames,
                  lastName: healthRecord.lastName,
                  customaryName: healthRecord.customaryName,
                  gender: healthRecord.gender,
                  t,
                })
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
            <Typography variant={"subtitle2"} component="span" sx={{ color: theme.palette.neutral[500], fontSize: 14 }}>
              {healthRecord ? (
                `${localizedDate(healthRecord.birthDate, locale)} (${calculateAge(
                  utcToday(),
                  healthRecord.birthDate,
                  true,
                  t,
                )})`
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
          </Box>
        ) : (
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: theme.palette.neutral[600], fontWeight: "bold" }}
            data-testid="breadcrumbs"
            separator={<NavigateNextIcon fontSize={"medium"} />}
          >
            <Link
              underline="none"
              color={theme.palette.primary.main}
              to={pathname.substring(0, pathname.lastIndexOf("/health-records") + 15)}
              component={RouterLink}
              sx={{
                "&:hover": {
                  color: theme.palette.primary[300],
                },
              }}
            >
              <Typography variant={"h5"}>{t("patients.title")}</Typography>
            </Link>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Typography
                variant={"h5"}
                sx={{
                  maxWidth: "450px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                }}
              >
                {healthRecord ? (
                  getFullName({
                    firstNames: healthRecord.firstNames,
                    lastName: healthRecord.lastName,
                    customaryName: healthRecord.customaryName,
                    gender: healthRecord.gender,
                    t,
                  })
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </Typography>
              <Typography variant={"h6"} component="span" sx={{ color: theme.palette.neutral[500], ml: 2 }}>
                {healthRecord ? (
                  `${localizedDate(healthRecord.birthDate, locale)} (${calculateAge(
                    utcToday(),
                    healthRecord.birthDate,
                    true,
                    t,
                  )})`
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </Typography>
            </Box>
          </Breadcrumbs>
        )
      }
      tabs={<PageTabs />}
    >
      <Outlet />
    </PageLayout>
  );
}

function PageTabs() {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:x
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  let currentTab = "health-records/:id";
  const { t } = useI18n();
  const { pathname } = useLocation();

  if (pathname.endsWith("/edit")) {
    currentTab = "health-records/:id/edit";
  } else if (pathname.endsWith("/profile")) {
    currentTab = "health-records/:id/profile";
  } else if (pathname.endsWith("/diagnostic")) {
    currentTab = "health-records/:id/diagnostic";
  } else if (pathname.endsWith("/shares") || pathname.endsWith("/share")) {
    currentTab = "health-records/:id/shares";
  } else if (pathname.endsWith("/documents")) {
    currentTab = "health-records/:id/documents";
  }

  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  // const organizations = useAllOrganizations();
  // const noOrganization = !organizations || organizations?.length === 0;

  return (
    <Tabs
      value={currentTab}
      sx={{
        backgroundColor: "none",
        border: "none",
        px: { xs: 0, md: 2 },
        ".MuiButtonBase-root": {
          padding: { xs: "0 8px", md: "0 16px" },
        },
        ".MuiTabScrollButton-root": {
          color: theme.palette.primary[500],
        },
      }}
      variant={breakpointSm ? "fullWidth" : "standard"}
    >
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.immunization")}
          </Typography>
        }
        value="health-records/:id"
        to=""
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.profile")}
          </Typography>
        }
        value="health-records/:id/profile"
        to="profile"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.vaccinations_to_be_done")}
          </Typography>
        }
        value="health-records/:id/diagnostic"
        to="diagnostic"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>
            {t("patients.tabs.identity")}
          </Typography>
        }
        value="health-records/:id/edit"
        to="edit"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>{t("patients.tabs.share")}</Typography>
        }
        value="health-records/:id/shares"
        to="shares"
        component={RouterLink}
      />
      <Tab
        label={
          <Typography sx={{ fontSize: breakpointSm ? 10 : 14, fontWeight: 500 }}>Documents</Typography>
        }
        value="health-records/:id/documents"
        to="documents"
        component={RouterLink}
      />
    </Tabs>
  );
}
