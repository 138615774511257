import { useEffect, useState } from "react";
import { DomainHealthRecord } from "../../domain/healthRecord";
import { useApis } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";

export function useUnsharedHealthRecord(id: string): {
  isLoading: boolean;
  unsharedHealthRecord?: DomainHealthRecord;
  error?: Response | Error;
} {
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const [unsharedHealthRecord, setUnsharedHealthRecord] = useState<DomainHealthRecord | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Wrap this inside a query
        if (organizationId && teamId) {
          const { unsharedHealthRecord } = await apis.team.unsharedHealthRecordApi.getUnsharedHealthRecord(
            organizationId,
            teamId,
            id,
          );
          setUnsharedHealthRecord(unsharedHealthRecord);
        } else {
          const { unsharedHealthRecord } = await apis.pro.unsharedHealthRecordApi.getUnsharedHealthRecord(id);
          setUnsharedHealthRecord(unsharedHealthRecord);
        }
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [apis, id, organizationId, teamId]);

  return { isLoading, unsharedHealthRecord, error };
}
