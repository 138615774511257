import { useState, useCallback, useMemo } from 'react';
import { Box, IconButton, Typography, Grid2 } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useI18n } from '../../../hooks/useI18n';
import SortableListHeader from './SortableListHeader';
import { Document } from "@syadem/kairos-pro-js";

interface DocumentListProps {
  documents: Document[];
  onDownload: (documentId: string, attachment: { fileId: string; filename: string }) => void;
  onDelete: (documentId: string) => void;
  onPreview: (documentId: string, attachment: { fileId: string; filename: string }) => void;
  onEdit: (document: Document) => void;
}

function DocumentList({ documents, onDownload, onDelete, onPreview, onEdit }: DocumentListProps) {
  const { t } = useI18n();
  const theme = useTheme();
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("date");

  const updateSort = useCallback(
    (newOrderBy: string) => {
      if (newOrderBy === orderBy) {
        setOrder(order === "asc" ? "desc" : "asc");
      } else {
        setOrderBy(newOrderBy);
        setOrder("asc");
      }
    },
    [order, orderBy],
  );

  const sortedDocuments = useMemo(() => {
    const getValueFn = (document: Document): string => {
      switch (orderBy) {
        case "name":
          return document.name;
        case "category":
          return document.category;
        default:
          return "";
      }
    };

    const sortFn = (a: Document, b: Document) => {
      if (orderBy === "date") {
        if (!a.date) return 1;
        if (!b.date) return -1;
        return order === "asc" ? a.date.getTime() - b.date.getTime() : b.date.getTime() - a.date.getTime();
      }

      const aValue = getValueFn(a);
      const bValue = getValueFn(b);
      return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    };

    return [...documents].sort(sortFn);
  }, [documents, order, orderBy]);

  return (
    <>
      <SortableListHeader
        columns={[
          { id: 'name', label: t('documents.attributes.name'), width: 4 },
          { id: 'category', label: t('documents.attributes.category'), width: 2 },
          { id: 'date', label: t('documents.attributes.date'), width: 2 },
          { id: 'attachments', label: t('documents.attributes.attachments'), width: 4, sortable: false },
        ]}
        updateSort={updateSort}
        order={order}
        orderBy={orderBy}
      />
      <Box>
        {sortedDocuments.map((document) => (
          <Grid2
            container
            key={document.id}
            sx={{
              bgcolor: "background.paper",
              mb: 0.2,
              border: `solid 1px ${theme.palette.neutral[200]}`,
              borderRadius: 1,
              py: 1,
              px: 2,
              "&:hover": {
                border: `solid 1px ${theme.palette.primary[500]}`,
                cursor: "pointer",
              },
            }}
            onClick={() => onEdit(document)}
          >
            <Grid2 container size={11} alignItems="center">
              <Grid2 size={{ xs: 12, md: 4 }}>
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {document.name}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 2 }}>
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {t(`documents.attributes.categories.${document.category}`)}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 2 }}>
                <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {document.date?.toLocaleDateString()}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                {document.attachments.map((attachment) => (
                  <Box key={attachment.fileId} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {attachment.filename}
                    </Typography>
                    <IconButton
                      color="primary"
                      sx={{ p: 0.2, ml: 2 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onPreview(document.id, attachment);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      sx={{ p: 0.2, ml: 0.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDownload(document.id, attachment);
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Grid2>
            </Grid2>
            <Grid2 size={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(document.id);
                }}
                size="small"
                color="error"
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Grid2>
          </Grid2>
        ))}
      </Box>
    </>
  );
}

export default DocumentList;
