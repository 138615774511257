import { ApiClient as ArianeApi, initAriane } from "@syadem/ariane-js";
import {
  Configuration as ProConfiguration,
  ProfessionalAccountApi,
  HealthRecordApi as ProHealthRecordApi,
  RecordSharingApi as ProRecordSharingApi,
  SharingRequestApi as ProSharingRequestApi,
  VaccinationActApi as ProVaccinationActApi,
  UnsharedHealthRecordApi as ProUnsharedHealthRecordApi,
  NoteApi as ProNoteApi,
  HealthProfileApi as ProHealthProfileApi,
  DocumentApi as ProDocumentApi,
} from "@syadem/kairos-pro-js";
import {
  OrganizationApi,
  RecordSharingApi as TeamRecordSharingApi,
  TeamApi,
  Configuration as TeamConfiguration,
  HealthRecordApi as TeamHealthRecordApi,
  SharingRequestApi as TeamSharingRequestApi,
  VaccinationActApi as TeamVaccinationActApi,
  UnsharedHealthRecordApi as TeamUnsharedHealthRecordApi,
  NoteApi as TeamNoteApi,
  HealthProfileApi as TeamHealthProfileApi,
  DocumentApi as TeamDocumentApi,
  EventLogApi as TeamEventLogApi,
} from "@syadem/kairos-team-js";
import { SadvApi, Configuration as SadvApiConfiguration } from "@syadem/sad-js";
import { ProKairosCertApi } from "./kairosCertApi";
import { TeamKairosCertApi } from "./kairosCertApi";
import { ProDocumentFileApi } from "./proDocumentFileApi";
import { TeamDocumentFileApi } from "./teamDocumentFileApi";

interface ApisDependencies {
  kairosProApiBasePath: string;
  kairosTeamApiBasePath: string;
  kairosCertApiBasePath: string;
  sadApiBasePath: string;
  arianeApiBasePath: string;
  accessToken: () => Promise<string>;
}

export interface Apis {
  pro: {
    healthRecordApi: ProHealthRecordApi;
    professionalAccountApi: ProfessionalAccountApi;
    recordSharingApi: ProRecordSharingApi;
    sharingRequestApi: ProSharingRequestApi;
    vaccinationActApi: ProVaccinationActApi;
    unsharedHealthRecordApi: ProUnsharedHealthRecordApi;
    noteApi: ProNoteApi;
    healthProfileApi: ProHealthProfileApi;
    kairosCertApi: ProKairosCertApi;
    documentApi: ProDocumentApi;
    documentFileApi: ProDocumentFileApi;
  };
  team: {
    healthRecordApi: TeamHealthRecordApi;
    teamApi: TeamApi;
    organizationApi: OrganizationApi;
    recordSharingApi: TeamRecordSharingApi;
    sharingRequestApi: TeamSharingRequestApi;
    vaccinationActApi: TeamVaccinationActApi;
    unsharedHealthRecordApi: TeamUnsharedHealthRecordApi;
    noteApi: TeamNoteApi;
    healthProfileApi: TeamHealthProfileApi;
    kairosCertApi: TeamKairosCertApi;
    documentApi: TeamDocumentApi;
    documentFileApi: TeamDocumentFileApi;
    eventLogApi: TeamEventLogApi;
  };
  sadApi: SadvApi;
  arianeApi: ArianeApi;
}

export function initApis(dependencies: ApisDependencies): Apis {
  const kairosProApiConfig = new ProConfiguration({
    basePath: dependencies.kairosProApiBasePath,
    accessToken: dependencies.accessToken,
  });

  const kairosTeamApiConfig = new TeamConfiguration({
    basePath: dependencies.kairosTeamApiBasePath,
    accessToken: dependencies.accessToken,
  });

  return {
    pro: {
      healthRecordApi: new ProHealthRecordApi(kairosProApiConfig),
      professionalAccountApi: new ProfessionalAccountApi(kairosProApiConfig),
      recordSharingApi: new ProRecordSharingApi(kairosProApiConfig),
      sharingRequestApi: new ProSharingRequestApi(kairosProApiConfig),
      vaccinationActApi: new ProVaccinationActApi(kairosProApiConfig),
      unsharedHealthRecordApi: new ProUnsharedHealthRecordApi(kairosProApiConfig),
      noteApi: new ProNoteApi(kairosProApiConfig),
      healthProfileApi: new ProHealthProfileApi(kairosProApiConfig),
      kairosCertApi: new ProKairosCertApi(kairosProApiConfig, dependencies.kairosCertApiBasePath),
      documentApi: new ProDocumentApi(kairosProApiConfig),
      documentFileApi: new ProDocumentFileApi(kairosProApiConfig)
    },
    team: {
      healthRecordApi: new TeamHealthRecordApi(kairosTeamApiConfig),
      teamApi: new TeamApi(kairosTeamApiConfig),
      organizationApi: new OrganizationApi(kairosTeamApiConfig),
      recordSharingApi: new TeamRecordSharingApi(kairosTeamApiConfig),
      sharingRequestApi: new TeamSharingRequestApi(kairosTeamApiConfig),
      vaccinationActApi: new TeamVaccinationActApi(kairosTeamApiConfig),
      unsharedHealthRecordApi: new TeamUnsharedHealthRecordApi(kairosTeamApiConfig),
      noteApi: new TeamNoteApi(kairosTeamApiConfig),
      healthProfileApi: new TeamHealthProfileApi(kairosTeamApiConfig),
      kairosCertApi: new TeamKairosCertApi(kairosTeamApiConfig, dependencies.kairosCertApiBasePath),
      documentApi: new TeamDocumentApi(kairosTeamApiConfig),
      documentFileApi: new TeamDocumentFileApi(kairosTeamApiConfig),
      eventLogApi: new TeamEventLogApi(kairosTeamApiConfig),
    },
    sadApi: new SadvApi(new SadvApiConfiguration({ basePath: dependencies.sadApiBasePath })),
    arianeApi: initAriane(dependencies.arianeApiBasePath),
  };
}
