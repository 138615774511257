import { Professional } from "../domain/professional";

export function CapitalizeFirstNames(value: string): string {
  return value
    .split(" ")
    .map((name) =>
      name
        .split("-")
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join("-")
    )
    .join(" ");
}

export function FormatFirstName(firstName: string): string {
  return firstName
    .toLocaleLowerCase()
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join("-");
}

export function ProfessionalName(professional: Professional): string {
  return `${professional?.lastName?.toUpperCase()} ${FormatFirstName(professional?.firstName || "")}`;
}
