import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { Avatar, Box, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";
import { DomainHealthRecord } from "../../../domain/healthRecord";
import { calculateAge, utcToday } from "../../../utils/ageCalculator";
import { localizedDate, formatDistanceToNow } from "../../../utils/dayjs";
import { getFullName, isSharedToOtherProOrTeam, isSharedToPatient } from "../../../utils/healthRecord";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";

interface HealthRecordListItemContentProps {
  healthRecord: DomainHealthRecord;
  icon: ReactNode;
  testId?: string;
}

export default function HealthRecordListItemContent({ healthRecord, icon, testId }: HealthRecordListItemContentProps) {
  const { t, locale } = useI18n();
  return (
    <>
      <ListItemAvatar sx={{ minWidth: { xs: 48, md: 56 } }}>
        <Avatar
          sx={{
            background: theme.palette.primary[100],
            width: 40,
            height: 40,
            mr: { xs: 0, md: 2 },
          }}
        >
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          ".MuiListItemText-secondary": {
            display: "flex",
          },
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        primary={
          <Box sx={{ display: "flex" }}>
            <Typography
              fontWeight="500"
              color={theme.palette.neutral[600]}
              data-testid={`${testId}Names`}
              sx={{
                maxWidth: "450px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            >
              {getFullName({
                firstNames: healthRecord.firstNames,
                lastName: healthRecord.lastName,
                customaryName: healthRecord.customaryName,
                gender: healthRecord.gender,
                t,
              })}
            </Typography>
            {isSharedToPatient(healthRecord) && (
              <Tooltip title={t("health_records.shared")} arrow>
                <Box
                  sx={{
                    ml: 1,
                    bgcolor: "rgb(237, 247, 237)",
                    color: "#4caf50",
                    height: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    padding: "2px",
                  }}
                >
                  <ShareIcon fontSize="small" />
                  <PersonIcon fontSize="small" />
                </Box>
              </Tooltip>
            )}
            {isSharedToOtherProOrTeam(healthRecord) && (
              <Tooltip title={t("health_records.shared_pro")} arrow>
                <Box
                  sx={{
                    ml: 1,
                    bgcolor: theme.palette.primary[100],
                    height: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    padding: "2px",
                    color: theme.palette.primary[400],
                  }}
                >
                  <ShareIcon fontSize="small" />
                  <LocalHospitalIcon fontSize="small" />
                </Box>
              </Tooltip>
            )}
          </Box>
        }
        secondary={
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }} component="span">
            <Typography
              variant="body2"
              color={theme.palette.neutral[500]}
              component="span"
              data-testid={`${testId}Birthdate`}
            >
              {`${localizedDate(healthRecord.birthDate, locale)} (${calculateAge(
                utcToday(),
                healthRecord.birthDate,
                true,
                t,
              )})`}
            </Typography>
            {healthRecord.externalId ? (
              <Box sx={{ display: "inline-flex", ml: { xs: 0, md: 2 } }} component="span">
                <SubtitlesIcon color="disabled" fontSize="small" sx={{ marginRight: "5px" }} />
                <Typography
                  variant="body2"
                  color={theme.palette.neutral[500]}
                  component="span"
                  data-testid={`${testId}ExternalId`}
                >
                  {healthRecord.externalId}
                </Typography>
              </Box>
            ) : null}
            <Typography
              variant="body2"
              color={theme.palette.neutral[500]}
              sx={{ display: { xs: "block", sm: "none" }, pt: 1 }}
              component="span"
              data-testid={`${testId}LastModified`}
            >
              {t(`common.dates.lastModified`, {
                distance: formatDistanceToNow(locale, healthRecord.updatedAt),
              })}
            </Typography>
          </Box>
        }
      />
    </>
  );
}
