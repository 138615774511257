import { useI18n } from "../../src/ui/hooks/useI18n";
import { forwardRef, useState } from "react";
import { useCountryConfig } from "../../src/ui/hooks/useCountryConfig";
import ReactInputMask from "react-input-mask-format";
import { SecurityNumberInputProps } from "../../src/appConfig";
import { IdentityGenderEnum } from "@syadem/kairos-team-js";
import { Dayjs } from "dayjs";
import { dayjs } from "../../src/utils/dayjs";
import { LightStyledInput } from "../../src/ui/components/mui/LightStyledInput";

export function SecurityNumberInput(props: SecurityNumberInputProps) {
  const {
    isReadonly,
    gender,
    setGender,
    birthDate,
    setBirthDate,
    defaultValue
  } = props;

  const { t } = useI18n();
  const { securityNumber } = useCountryConfig();
  const [invalidNumber, setInvalidNumber] = useState<boolean>(false);

  const extractData = (value: string) => {
    const cleanedValue = value.trim().replaceAll(" ", "").replaceAll("_", "");
    const isValid = cleanedValue && securityNumber.validator(cleanedValue);

    if (isValid) {
      const genderFromExternalId = retrieveGenderFromExternalId(cleanedValue.slice(9, 10));

      const birthDateFromExternalId = retrieveBirthDateFromExternalId(
        cleanedValue.slice(0, 2),
        cleanedValue.slice(2, 4),
        cleanedValue.slice(4, 6),
      );

      if (genderFromExternalId !== undefined && !gender) {
        setGender(genderFromExternalId);
      }

      if (!birthDate) {
        setBirthDate(birthDateFromExternalId);
      }
    }
  }

  return (
    <>
      <LightStyledInput
        fullWidth
        label={t("common.user_infos.social_security_number")}
        placeholder="ex : 010130 9999 7"
        inputComponent={!isReadonly ? (SocialSecurityMaskInput as any) : undefined}
        readOnly={isReadonly}
        name="externalId"
        onChange={(e) => {
          setInvalidNumber(false);
          extractData(e.target.value);
        }}
        defaultValue={defaultValue}
        onBlur={(e) => setInvalidNumber(!securityNumber.validator((e.target.value)))}
        error={invalidNumber}
        errorMessage={invalidNumber ? t("common.errors.securityNumber") : undefined}
      />
    </>
  );
}

function retrieveGenderFromExternalId(genderDigit: string): IdentityGenderEnum | undefined {
  if (genderDigit === undefined ) { return undefined; } 
  
  if (parseInt(genderDigit) % 2 == 0) {
    return "M";
  } else {
    return "F";
  }
}

function retrieveBirthDateFromExternalId(yearDigit: string, monthDigit: string, dayDigit: string): Dayjs {
  const yearFromExternalId = new Date().getFullYear().toString().slice(0, 2) + yearDigit;
  const birthDateFromExternalId = new Date(`${yearFromExternalId}-${monthDigit}-${dayDigit}`);

  if (birthDateFromExternalId > new Date()) {
    birthDateFromExternalId.setFullYear(parseInt(yearFromExternalId) - 100);
  }

  return dayjs.utc(birthDateFromExternalId);
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: string;
}

const SocialSecurityMaskInput = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  return (
    <ReactInputMask
      {...props}
      mask="999999 9999 9"
      onChange={props.onChange}
      value={props.value}
      ref={ref as any}
    />
  );
});
