import { Configuration, Document } from "@syadem/kairos-pro-js";

export interface ProDocumentFileApiInterface {
  attachFiles(
    healthRecordId: string,
    documentId: string,
    attachments: File[],
    onProgressUpdate?: (progress: number) => void,
  ): Promise<Document>;
  downloadFile(options: {
    healthRecordId: string;
    documentId: string;
    attachment: { fileId: string; filename: string };
  }): Promise<Blob>;
}

export class ProDocumentFileApi implements ProDocumentFileApiInterface {
  constructor(private readonly configuration: Configuration) {}

  async attachFiles(
    healthRecordId: string,
    documentId: string,
    attachments: File[],
    onProgressUpdate?: (progress: number) => void,
  ): Promise<Document> {
    const formData = new FormData();
    attachments.forEach((a) => formData.append("attachments[]", a));

    let token = "";
    if (this.configuration.accessToken) {
      token = await this.configuration.accessToken();
    }

    const totalBytes = attachments.reduce((sum, file) => sum + file.size, 0);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgressUpdate) {
          const progress = event.loaded / totalBytes;
          onProgressUpdate(progress);
        }
      };

      xhr.onload = async function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const body = JSON.parse(xhr.responseText) as {
              id: string;
              name: string;
              category: Document["category"];
              health_record_id: string;
              attachments: any[];
              date: string | null;
              created_at: string;
            };

            const document: Document = {
              id: body.id,
              healthRecordId: healthRecordId,
              name: body.name,
              category: body.category,
              attachments: body.attachments.map((a) => ({ fileId: a.file_id, filename: a.filename })),
              date: body.date ? new Date(body.date) : null,
              createdAt: new Date(body.created_at),
            };
            console.log("Document created successfully:", document);
            resolve(document);
          } catch (error) {
            console.error("Error parsing response:", error);
            reject(new Error("Failed to parse server response"));
          }
        } else {
          console.error("Upload failed with response:", xhr.responseText);
          reject(new Error(`Failed to upload files: ${xhr.status} ${xhr.statusText}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error("Network error occurred during upload"));
      };

      xhr.onabort = () => {
        reject(new Error("Upload was aborted"));
      };

      xhr.open(
        "POST",
        `${this.configuration.basePath}/files/health_records/${healthRecordId}/documents/${documentId}/attach`,
        true,
      );
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.send(formData);
    });
  }

  async downloadFile(options: {
    healthRecordId: string;
    documentId: string;
    attachment: { fileId: string; filename: string };
  }): Promise<Blob> {
    const { healthRecordId, documentId, attachment } = options;
    let token = "";
    if (this.configuration.accessToken) {
      token = await this.configuration.accessToken();
    }

    const url = new URL(
      `${this.configuration.basePath}/files/health_records/${healthRecordId}/documents/${documentId}/download`,
    );
    url.searchParams.append("document_id", documentId);
    url.searchParams.append("file_id", attachment.fileId);
    url.searchParams.append("filename", attachment.filename);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/octet-stream",
      },
    });

    return response.blob();
  }
}
