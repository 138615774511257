import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid2, Alert } from "@mui/material";
import { Formik } from "formik";
import { City } from "@syadem/ariane-js";
import { ReactNode, useMemo, useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { useServiceBus } from "../../hooks/useServiceBus";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import yup from "../../../utils/yup";
import { StyledInput } from "../../components/mui/StyledInput";
import { CitiesAutocomplete } from "../../components/CitiesAutocomplete";
import { createTranslateFn, TranslateFunction } from "../../../utils/formUtils";

interface NewTeamDialogProps {
  open: boolean;
  onClose: () => void;
  organizationId: string;
  onSuccess: () => void;
}

export function NewTeamDialog({ open, onClose, organizationId, onSuccess }: NewTeamDialogProps) {
  const { t } = useI18n();
  const serviceBus = useServiceBus();
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const [teamCity, setTeamCity] = useState<City | undefined>(undefined);
  const { zipCode, phoneNumber } = useCountryConfig();

  const teamCreationSchema = yup.object({
    name: yup.string().max(50).required(),
    address: yup.string().required(),
    city: yup.string().required(),
    zipCode: yup.string().required().min(zipCode.length).max(zipCode.length).matches(zipCode.regex),
    phoneNumber: yup
      .string()
      .required()
      .min(phoneNumber.minLength)
      .max(phoneNumber.maxLength)
      .matches(phoneNumber.regex),
  });

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          // Handle dialog close properly
          setErrorMessage(undefined);
          setTeamCity(undefined);
        }
        onClose();
      }}
      maxWidth="sm"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle>{t("common.interface.newTeam")}</DialogTitle>
      <Alert severity="info" sx={{ maxWidth: "md", marginX: "auto", mb: 2, mx: 3 }}>
        {t("team.createPending.description")}
      </Alert>
      <Formik
        initialValues={{
          name: "",
          address: "",
          city: "",
          zipCode: "",
          phoneNumber: "",
        }}
        validationSchema={teamCreationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (organizationId) {
            setErrorMessage(undefined);
            try {
              await serviceBus.dispatch({
                type: "createTeam",
                organizationId,
                team: values,
              });
              onSuccess();
            } catch (_e) {
              setSubmitting(false);
              setErrorMessage(t("common.alerts.alert_notification"));
            }
          }
        }}
      >
        {({ errors, getFieldProps, touched, handleSubmit, setFieldValue, setFieldTouched, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid2 container spacing={2}>
                {errorMessage && (
                  <Grid2 size={12}>
                    <Alert severity="error">{errorMessage}</Alert>
                  </Grid2>
                )}
                <Grid2 size={12}>
                  <StyledInput
                    id="name"
                    fullWidth
                    size="small"
                    error={touched.name && !!errors.name}
                    errorMessage={errors.name}
                    touched={touched.name}
                    translateErrors={translateErrors}
                    label={t("team.name")}
                    placeholder={t("team.namePlaceholder")}
                    required
                    {...getFieldProps("name")}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <StyledInput
                    id="phoneNumber"
                    fullWidth
                    size="small"
                    error={touched.phoneNumber && !!errors.phoneNumber}
                    errorMessage={errors.phoneNumber}
                    touched={touched.phoneNumber}
                    translateErrors={translateErrors}
                    label={t("team.phoneNumber")}
                    required
                    placeholder={phoneNumber.placeholder}
                    {...getFieldProps("phoneNumber")}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <StyledInput
                    id="address"
                    fullWidth
                    size="small"
                    error={touched.address && !!errors.address}
                    errorMessage={errors.address}
                    touched={touched.address}
                    translateErrors={translateErrors}
                    label={t("team.address")}
                    required
                    {...getFieldProps("address")}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <StyledInput
                    id="zipCode"
                    fullWidth
                    size="small"
                    error={touched.zipCode && !!errors.zipCode}
                    errorMessage={errors.zipCode}
                    touched={touched.zipCode}
                    translateErrors={translateErrors}
                    label={t("team.zipCode")}
                    required
                    placeholder={zipCode.placeholder}
                    {...getFieldProps("zipCode")}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <CitiesAutocomplete
                    label={t("team.city")}
                    error={touched.city && !!errors.city}
                    errorMessage={errors.city}
                    touched={touched.city}
                    translateErrors={translateErrors}
                    placeholder={t("team.city")}
                    required
                    cityCode={teamCity?.code}
                    onChange={(city) => {
                      setFieldTouched("city", true);
                      if (!city) {
                        setFieldValue("city", "");
                        setTeamCity(undefined);
                      } else {
                        setFieldValue("city", city.name);
                        setTeamCity(city);
                      }
                    }}
                  />
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>{t("common.cta.cancel")}</Button>
              <Button type="submit" variant="contained" loading={isSubmitting} disableElevation>
                {t("common.cta.add")}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
