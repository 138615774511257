import { Box } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useDaphne } from "../../../hooks";
import { useI18n } from "../../../hooks/useI18n";
import { dayjs, localizedDate } from "../../../../utils/dayjs";
import { useFetchHealthRecordVaccinations } from "../../../hooks/useHealthRecordVaccinations";
import { getTranslatedFieldByLocale } from "../../../../utils/translatedFields";
import { useAuthenticatedUser, useHealthRecord } from "../../../../store";

export const PdfView = ({ id, healthRecordId, shareUrl }: { id: string; healthRecordId: string; shareUrl: string }) => {
  const daphne = useDaphne();
  const { t, locale } = useI18n();
  const currentUser = useAuthenticatedUser();

  const { isLoading } = useFetchHealthRecordVaccinations(healthRecordId);
  const healthRecord = useHealthRecord(healthRecordId);

  return (
    <Box display="none" id={id} style={{ WebkitPrintColorAdjust: "exact" }}>
      <h2 style={{ fontFamily: "sans-serif", paddingBottom: "2rem" }}>
        {t("pdfView.shareHealthRecord", { user: `${currentUser?.firstName} ${currentUser?.lastName}` })}
      </h2>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <QRCodeSVG value={shareUrl} size={300} />
        <p style={{ margin: "2rem 2rem", fontFamily: "sans-serif" }}>
          {t("pdfView.qrCode", { date: localizedDate(dayjs().add(30, "day"), locale) })}
        </p>
        {daphne && !isLoading && healthRecord?.vaccinationActs && healthRecord?.vaccinationActs.length > 0 && (
          <>
            <h3 style={{ fontFamily: "sans-serif" }}>
              {t("pdfView.vaccinesList", { date: new Date().toLocaleDateString(locale) })}
            </h3>
            <table
              align="center"
              style={{
                borderCollapse: "collapse",
                border: "1px solid black",
                margin: "1rem auto",
              }}
            >
              <thead>
                <tr>
                  <th style={{ border: "1px solid black" }}>Date</th>
                  <th style={{ border: "1px solid black" }}>{t("patients.name")}</th>
                </tr>
              </thead>
              <tbody>
                {healthRecord?.vaccinationActs.map((act, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        WebkitPrintColorAdjust: "exact",
                        border: "1px solid black",
                        backgroundColor: index % 2 === 0 ? "lightgrey" : "white",
                      }}
                    >
                      <td style={{ border: "1px solid black" }}>{act.performedOn.toLocaleDateString(locale)}</td>
                      <td>
                        {getTranslatedFieldByLocale(locale, daphne.repositories.vaccines.find(act.vaccineId).name)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Box>
  );
};
