import { DocumentApiInterface as ProDocumentApiInterface } from "@syadem/kairos-pro-js";
import { DocumentApiInterface as TeamDocumentApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";

interface Payload {
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  documentId: string;
}

export class DeleteDocumentService implements Service<Payload, void> {
  constructor(
    private readonly proDocumentApi: ProDocumentApiInterface,
    private readonly teamDocumentApi: TeamDocumentApiInterface,
    private readonly store: AppStore,
  ) {}

  async call({ healthRecordId, organizationId, teamId, documentId }: Payload): Promise<ActionResult<void>> {
    if (organizationId && teamId) {
      await this.teamDocumentApi.deleteDocument(organizationId, teamId, healthRecordId, documentId);
    } else {
      await this.proDocumentApi.deleteDocument(healthRecordId, documentId);
    }

    this.removeDocumentFromStore(healthRecordId, documentId);

    return {
      type: "success",
      value: undefined,
    };
  }

  private removeDocumentFromStore(healthRecordId: string, documentId: string) {
    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        return {
          ...state,
          healthRecords: {
            ...state.healthRecords,
            [healthRecordId]: {
              ...state.healthRecords[healthRecordId],
              documents: state.healthRecords[healthRecordId].documents.filter((doc) => doc.id !== documentId),
            },
          },
        };
      }
      return state;
    });
  }
}
