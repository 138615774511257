import Box from "@mui/material/Box";
import { LOCALE_LABELS, LocaleString } from "../../translations";

const flagByLocale: Record<LocaleString, string> = {
  "fr": "🇫🇷",
  "en-gb": "🇬🇧",
  "lv": "🇱🇻",
  "gr": "🇬🇷"
};

export function FlagIcon({ locale }: { locale: LocaleString }) {
  return (
    <Box sx={{ fontSize: "14px" }} display="flex" alignItems="center">
      <Box mr={2}>{flagByLocale[locale]}</Box>
      {LOCALE_LABELS[locale]}
    </Box>
  );
}
