import {
  DocumentApiInterface as ProDocumentApiInterface,
  Document as ProDocument,
} from "@syadem/kairos-pro-js";
import {
  UpdateDocumentBody,
  UpdateDocumentBodyDocument,
  DocumentApiInterface as TeamDocumentApiInterface,
  Document as TeamDocument,
} from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";

interface Payload {
  document: {
    name?: UpdateDocumentBodyDocument["name"];
    category?: UpdateDocumentBodyDocument["category"];
    date?: UpdateDocumentBodyDocument["date"];
  };
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  documentId: string;
}

export class UpdateDocumentService implements Service<Payload, ProDocument | TeamDocument> {
  constructor(
    private readonly proDocumentApi: ProDocumentApiInterface,
    private readonly teamDocumentApi: TeamDocumentApiInterface,
    private readonly store: AppStore,
  ) { }

  async call({
    document,
    healthRecordId,
    organizationId,
    teamId,
    documentId,
  }: Payload): Promise<ActionResult<ProDocument | TeamDocument>> {
    let apiDocument: ProDocument | TeamDocument;

    const updateDocumentBody: UpdateDocumentBody = {
      document: document as UpdateDocumentBodyDocument,
    };

    if (organizationId && teamId) {
      apiDocument = (
        await this.teamDocumentApi.updateDocument(organizationId, teamId, healthRecordId, documentId, updateDocumentBody)
      );
    } else {
      apiDocument = (await this.proDocumentApi.updateDocument(healthRecordId, documentId, updateDocumentBody));
    }

    this.updateDocumentInStore(healthRecordId, apiDocument);

    return {
      type: "success",
      value: apiDocument,
    };
  }

  private updateDocumentInStore(healthRecordId: string, document: ProDocument | TeamDocument) {
    this.store.setState((state) => {
      if (state.healthRecords && state.healthRecords[healthRecordId]) {
        return {
          ...state,
          healthRecords: {
            ...state.healthRecords,
            [healthRecordId]: {
              ...state.healthRecords[healthRecordId],
              documents: state.healthRecords[healthRecordId].documents.map((doc) => {
                if (doc.id === document.id) {
                  return document;
                }
                return doc;
              }),
            },
          },
        };
      }
      return state;
    });
  }
}
