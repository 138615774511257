import { useCallback, useEffect, useRef, useState } from "react";
import { useQueries } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { useHealthRecord } from "../../store";

export function useFetchHealthRecordVaccinations(id: string): {
  isLoading: boolean;
  error?: Response | Error;
  refreshVaccinations: () => void;
} {
  const { organizationId, teamId } = useAppContext();
  const healthRecord = useHealthRecord(id);

  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const queries = useQueries();
  const fetchingRef = useRef(false);

  const fetchVaccinationActs = useCallback(async () => {
    try {
      if (!healthRecord) return;
      fetchingRef.current = true;
      setIsLoading(true);
      setError(undefined);

      if (organizationId && teamId) {
        await queries.teamVaccinationActsQuery.call(organizationId, teamId, id);
      } else {
        await queries.proVaccinationActsQuery.call(id);
      }
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        setError(error);
      } else {
        throw error;
      }
    } finally {
      fetchingRef.current = false;
      setIsLoading(false);
    }
  }, [id, organizationId, teamId, healthRecord, queries.proVaccinationActsQuery, queries.teamVaccinationActsQuery]);

  useEffect(() => {
    if (fetchingRef.current) return;
    fetchVaccinationActs();
  }, [fetchVaccinationActs]);

  return {
    error,
    isLoading,
    refreshVaccinations: fetchVaccinationActs,
  };
}
