import ArchiveIcon from "@mui/icons-material/Archive";
import { Alert, Box, FormControlLabel, LinearProgress, List, ListItem, ListItemText, Switch } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DomainHealthRecord } from "../../domain/healthRecord";
import { formatDistanceToNow } from "../../utils/dayjs";
import { replaceSpecialChars } from "../../utils/string";
import { EmptyListPlaceholder } from "../components/shared/EmptyListPlaceholder";
import HealthRecordListItemContent from "../components/shared/HealthRecordListItemContent";
import HealthRecordSearchBar from "../components/shared/HealthRecordSearchBar";
import { ListItemLink } from "../components/shared/ListItemLink";
import { useAllUnsharedHealthRecords } from "../hooks/useAllUnsharedHealthRecords";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";

const NoUnsharedHealthRecords = () => {
  const { t } = useI18n();
  return (
    <EmptyListPlaceholder
      title={t("health_records.none_unshared")}
      icon={<ArchiveIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
    >
      {t("health_records.history")}
    </EmptyListPlaceholder>
  );
};

export function TeamUnsharedHealthRecords() {
  const { t, locale } = useI18n();
  const { isLoading, unsharedHealthRecords, error } = useAllUnsharedHealthRecords();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [filteredHealthRecords, setFilteredHealthRecords] = useState<DomainHealthRecord[] | undefined>(
    unsharedHealthRecords,
  );

  useEffect(() => {
    (() => {
      if (unsharedHealthRecords === undefined) {
        return setFilteredHealthRecords(undefined);
      }
      if (searchValue === undefined || searchValue.trim() === "") {
        setFilteredHealthRecords(unsharedHealthRecords);
      } else {
        const fuse = new Fuse(unsharedHealthRecords, {
          keys: [
            { name: "firstNames", getFn: (record) => replaceSpecialChars(record.firstNames) },
            { name: "lastName", getFn: (record) => replaceSpecialChars(record.lastName) },
            {
              name: "firstNameLastname",
              getFn: (record) => replaceSpecialChars(`${record.firstNames} ${record.lastName}`),
            },
            {
              name: "lastnameFirstName",
              getFn: (record) => replaceSpecialChars(`${record.lastName} ${record.firstNames}`),
            },
            {
              name: "customaryName",
              getFn: (record) => (record.customaryName && replaceSpecialChars(record.customaryName)) || "",
            },
            { name: "externalId", getFn: (record) => record.externalId || "" },
            {
              name: "externalIdWithoutSpaces",
              getFn: (record) => record.externalId?.replaceAll(" ", "") || "",
            },
            { name: "birthDate", getFn: (record) => record.birthDate.toLocaleDateString(locale as string) },
          ],
          threshold: 0.1,
        });

        setFilteredHealthRecords(fuse.search(replaceSpecialChars(searchValue)).map((result) => result.item));
      }
    })();
  }, [unsharedHealthRecords, searchValue, locale]);

  return (
    <PageLayout title={t("common.interface.unshared_health_records")}>
      <Paper sx={{ overflow: "hidden", height: "100%", backgroundColor: "inherit" }} elevation={0}>
        {error ? (
          <Typography sx={{ my: 4, mx: 2 }} align="center" component="div">
            <Alert data-testid="alert" severity="error">
              {t("common.alerts.alert_notification")}
            </Alert>
          </Typography>
        ) : null}

        {isLoading && <LinearProgress variant="query" data-testid="progressbar" />}

        {unsharedHealthRecords && unsharedHealthRecords.length > 0 && (
          <>
            <HealthRecordSearchBar setSearchValue={setSearchValue} searchValue={searchValue} />
            {filteredHealthRecords && filteredHealthRecords.length > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-revert",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                    {t("common.resultsCount", {
                      smart_count: filteredHealthRecords?.length,
                      totalCount: unsharedHealthRecords?.length,
                    })}
                  </Typography>
                  <FormControlLabel
                    control={<Switch color="primary" defaultChecked onChange={() => navigate("../health-records")} />}
                    label={t("common.interface.unshared_health_records")}
                  />
                </Box>
                <UnsharedHealthRecordsList unsharedHealthRecords={filteredHealthRecords} />
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                  <FormControlLabel
                    control={<Switch color="primary" defaultChecked onChange={() => navigate("../health-records")} />}
                    label={t("common.interface.unshared_health_records")}
                  />
                </Box>
                <NoUnsharedHealthRecords />
              </>
            )}
          </>
        )}
        {!isLoading && (!unsharedHealthRecords || (unsharedHealthRecords && unsharedHealthRecords.length === 0)) && (
          <NoUnsharedHealthRecords />
        )}
      </Paper>
    </PageLayout>
  );
}

export function UnsharedHealthRecordsList(props: { unsharedHealthRecords: DomainHealthRecord[] }) {
  const { unsharedHealthRecords } = props;
  const { t, locale } = useI18n();

  return (
    <List sx={{ width: "100%" }}>
      {unsharedHealthRecords.map((healthRecord, index) => (
        <ListItem
          key={healthRecord.id}
          disablePadding
          sx={{ mb: 1 }}
          secondaryAction={
            <ListItemText
              sx={{
                marginY: "auto",
                mr: 2,
                display: { xs: "none", md: "block" },
              }}
              secondary={
                <Typography variant="body2" color={theme.palette.neutral[500]}>
                  {t(`common.dates.lastModified`, {
                    distance: formatDistanceToNow(locale, healthRecord.updatedAt),
                  })}
                </Typography>
              }
            />
          }
        >
          <ListItemLink
            to={`${healthRecord.id}`}
            sx={{
              p: 1,
              border: `solid 1px ${theme.palette.neutral[200]}`,
              borderRadius: 1,
              bgcolor: "background.paper",
              "&:hover": {
                border: `solid 1px ${theme.palette.primary[500]}`,
                bgcolor: "background.paper",
              },
            }}
            data-testid="unshared-health-record"
          >
            <HealthRecordListItemContent
              healthRecord={healthRecord}
              icon={<ArchiveIcon sx={{ color: theme.palette.primary[500] }} />}
              testId={`unsharedHealthRecordContent${index}`}
            />
          </ListItemLink>
        </ListItem>
      ))}
    </List>
  );
}
