import { createRoutesFromElements, Navigate, Route } from "react-router-dom";
import { RedirectToLiberalOrTeam } from "./components/RedirectToLiberalOrTeam";
import { GlobalFallback } from "./components/error_handling/GlobalFallback";
import { Layout } from "./layout/Layout";
import { HealthRecord } from "./pages/HealthRecord";
import { HealthRecords } from "./pages/HealthRecords";
import { HealthRecordsTransfer } from "./pages/HealthRecordsTransfer";
import { NotFound } from "./pages/NotFound";
import { SharingRequests } from "./pages/SharingRequests";
import { TeamHealthRecords } from "./pages/TeamHealthRecords";
import { TeamUnsharedHealthRecords } from "./pages/TeamUnsharedHealthRecords";
import { TriggerTestError } from "./pages/TriggerTestError";
import { UnsharedDiagnostic } from "./pages/UnsharedDiagnostic";
import { UnsharedHealthRecord } from "./pages/UnsharedHealthRecord";
import { UnsharedHealthRecords } from "./pages/UnsharedHealthRecords";
import { AccountVerification, RppsNumberVerification } from "./pages/VerificationPages";
import { AccountManagement } from "./pages/account/AccountManagement";
import { AddVaccinationType } from "./pages/health_record/AddVaccinationType";
import { EditHealthRecord } from "./pages/health_record/EditHealthRecord";
import { NewHealthRecord } from "./pages/health_record/NewHealthRecord";
import { Share } from "./pages/health_record/Share";
import { Shares } from "./pages/health_record/Shares";
import { UnsharedVaccinationActs } from "./pages/health_record/UnsharedVaccinationActs";
import { VaccinationActForm } from "./pages/health_record/VaccinationActForm";
import { VaccinationActs } from "./pages/health_record/VaccinationActs";
import { Diagnostic } from "./pages/health_record/diagnostic/Diagnostic";
import { Profile } from "./pages/health_record/profile/Profile";
import { ShowHealthRecord } from "./pages/health_record/unshared/ShowHealthRecord";
import { ShowProfile } from "./pages/health_record/unshared/ShowProfile";
import { ShowVaccination } from "./pages/health_record/unshared/ShowVaccination";
import { IndexMentor } from "./pages/mentor/Mentor";
import { EmptyOrganization } from "./pages/organization/EmptyOrganization";
import { NewOrganization } from "./pages/organization/NewOrganization";
import { OrganizationInfo } from "./pages/organization/settings/OrganizationInfo";
import { OrganizationSettings } from "./pages/organization/settings/OrganizationSettings";
import { OrganizationSubscription } from "./pages/organization/settings/OrganizationSubscription";
import { OrganizationTeams } from "./pages/organization/settings/OrganizationTeams";
import { AcceptInvitation } from "./pages/team/AcceptInvitation";
import { InviteMember } from "./pages/team/InviteMember";
import { Members } from "./pages/team/Members";
import { NewTeam } from "./pages/team/NewTeam";
import { Documents } from "./pages/health_record/documents/Documents";

export const routes = (subscriptionEnabled: boolean) =>
  createRoutesFromElements(
    <>
      {/*Redirect from root to liberal context if account is verified*/}
      <Route path="/" element={<RedirectToLiberalOrTeam />} errorElement={<GlobalFallback />} />

      <Route element={<Layout />} errorElement={<GlobalFallback />}>
        {/*Liberal context routes*/}
        <Route path="/liberal">
          <Route path="" element={<Navigate to="health-records" replace />} />
          <Route path="health-records" element={<HealthRecords />} />
          <Route path="health-records/new" element={<NewHealthRecord />} />
          <Route path="health-records/:id" element={<HealthRecord />}>
            <Route path="" element={<VaccinationActs />} />
            <Route path="profile" element={<Profile />} />
            <Route path="diagnostic" element={<Diagnostic />} />
            <Route path="vaccination/:vaccinationActId" element={<VaccinationActForm />} />
            <Route path="add-vaccination" element={<AddVaccinationType />} />
            <Route path="shares" element={<Shares />} />
            <Route path="share" element={<Share />} />
            <Route path="edit" element={<EditHealthRecord />} />
            <Route path="documents" element={<Documents />} />
          </Route>
          <Route path="unshared-health-records" element={<UnsharedHealthRecords />} />
          <Route path="unshared-health-records/:id" element={<UnsharedHealthRecord />}>
            <Route path="" element={<UnsharedVaccinationActs />} />
            <Route path="diagnostic" element={<UnsharedDiagnostic />} />
            <Route path="vaccination/:vaccinationActId" element={<ShowVaccination />} />
            <Route path="profile" element={<ShowProfile />} />
            <Route path="show" element={<ShowHealthRecord />} />
          </Route>
          <Route path="pending-sharing-requests" element={<SharingRequests />} />
          <Route path="rpps-missing" element={<RppsNumberVerification />} />
          <Route path="health-records-transfer" element={<HealthRecordsTransfer />} />
        </Route>

        {/*Team context routes*/}
        <Route path="/organizations">
          <Route path="mentor" element={<IndexMentor />} />
          <Route path="new" element={<NewOrganization />} />
          <Route path=":organizationId" element={<EmptyOrganization />} />
          <Route path=":organizationId/settings" element={<OrganizationSettings />}>
            <Route path="" element={<Navigate to="info" replace />} />
            {subscriptionEnabled && <Route path="subscription" element={<OrganizationSubscription />} />}
            <Route path="info" element={<OrganizationInfo />} />
            <Route path="teams" element={<OrganizationTeams />} />
          </Route>
          <Route path=":organizationId/teams/new" element={<NewTeam />} />
          <Route path=":organizationId/teams/:teamId">
            <Route path="" element={<Navigate to="health-records" replace />} />
            <Route path="invite-member" element={<InviteMember />} />
            <Route path="members" element={<Members />} />
            <Route path="health-records" element={<TeamHealthRecords />} />
            <Route path="health-records/new" element={<NewHealthRecord />} />
            <Route path="health-records/:id" element={<HealthRecord />}>
              <Route path="" element={<VaccinationActs />} />
              <Route path="profile" element={<Profile />} />
              <Route path="diagnostic" element={<Diagnostic />} />
              <Route path="vaccination/:vaccinationActId" element={<VaccinationActForm />} />
              <Route path="add-vaccination" element={<AddVaccinationType />} />
              <Route path="shares" element={<Shares />} />
              <Route path="share" element={<Share />} />
              <Route path="edit" element={<EditHealthRecord />} />
              <Route path="documents" element={<Documents />} />
            </Route>
            <Route path="unshared-health-records" element={<TeamUnsharedHealthRecords />} />
            <Route path="unshared-health-records/:id" element={<UnsharedHealthRecord />}>
              <Route path="" element={<UnsharedVaccinationActs />} />
              <Route path="diagnostic" element={<UnsharedDiagnostic />} />
              <Route path="vaccination/:vaccinationActId" element={<ShowVaccination />} />
              <Route path="profile" element={<ShowProfile />} />
              <Route path="show" element={<ShowHealthRecord />} />
            </Route>
          </Route>
        </Route>

        {/*Global routes*/}
        <Route path="join-team/:invitationId" element={<AcceptInvitation />} />
        <Route path="account" element={<AccountManagement />} />
        <Route path="/account-verification" element={<AccountVerification />} />
        <Route path="/test-error" element={<TriggerTestError />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </>,
  );
