export interface Log {
  id: string;
  emittedBy: string;
  rootId: string;
  type: LogType;
  data: {
    condition_id?: string;
    value?: string;
    validator_id?: string;
    account_id?: string;
    vaccine_id?: string;
    certificator_id?: string;
    shared_to_pro_external_id?: string;
    certification_method?: string;
    certified_on?: string;
    team_id?: string;
    from_team_id?: string;
    health_record_id?: string;
    shared_to?: string;
    shared_by?: string;
    account_type?: string;
    performed_on?: string;
    vaccinator_id?: string;
    batch_number?: string;
    serial_number?: string;
    injection_method?: string;
    injection_location?: string;
    booster?: string;
  } & object;
  createdAt: Date;
}

export enum LogType {
  VaccinationActCreated = "Hephaistos::HealthRecord::VaccinationAct::Created",
  VaccinationActUpdated = "Hephaistos::HealthRecord::VaccinationAct::Updated",
  VaccinationActDeleted = "Hephaistos::HealthRecord::VaccinationAct::Deleted",
  VaccinationActCertified = "Hephaistos::HealthRecord::VaccinationAct::Certified",
  HealthRecordCreated = "Hephaistos::HealthRecord::Created",
  HealthRecordUpdated = "Hephaistos::HealthRecord::Updated",
  HealthRecordDeleted = "Hephaistos::HealthRecord::Deleted",
  HealthRecordAccessGranted = "Hephaistos::HealthRecord::AccessGranted",
  HealthRecordOwnershipGranted = "Hephaistos::HealthRecord::OwnershipGranted",
  SharingRequestCreated = "Hephaistos::HealthRecord::SharingRequest::Created",
  SharingRequestAccepted = "Hephaistos::HealthRecord::SharingRequest::Accepted",
  SharingRequestDeleted = "Hephaistos::HealthRecord::SharingRequest::Deleted",
  SharingRequestRejected = "Hephaistos::HealthRecord::SharingRequest::Rejected",
  HealthRecordSharedWithTeam = "Hephaistos::HealthRecord::SharedWithTeam",
  HealthRecordUnsharedWithTeam = "Hephaistos::HealthRecord::UnsharedWithTeam",
  HealthRecordUnshared = "Hephaistos::HealthRecord::Unshared",
  HealthProfileConditionSet = "Hephaistos::HealthRecord::HealthProfile::ConditionSet",
  HealthProfileConditionUnset = "Hephaistos::HealthRecord::HealthProfile::ConditionUnset",
  OrganizationCreated = "Hephaistos::Organization::Created",
  OrganizationUpdated = "Hephaistos::Organization::Updated",
  OrganizationDeleted = "Hephaistos::Organization::Deleted",
  RoleGranted = "Hephaistos::Organization::RoleGranted",
  MemberAdded = "Hephaistos::Organization::MemberAdded",
  MemberRemoved = "Hephaistos::Organization::MemberRemoved",
  ProfessionalAccountRegisteredWithNationalProvider = "Hephaistos::ProfessionalAccount::RegisteredWithNationalProvider",
  ProfessionalAccountRegisteredWithProvider = "Hephaistos::ProfessionalAccount::RegisteredWithProvider",
  ProfessionalAccountRegisteredWithEmail = "Hephaistos::ProfessionalAccount::RegisteredWithEmail",
  ProfessionalAccountLinkedWithNationalProvider = "Hephaistos::ProfessionalAccount::LinkedWithNationalProvider",
  ProfessionalAccountLinkedWithProvider = "Hephaistos::ProfessionalAccount::LinkedWithProvider",
  ProfessionalAccountUpdated = "Hephaistos::ProfessionalAccount::Updated",
  ProfessionalAccountActivated = "Hephaistos::ProfessionalAccount::Activated",
  ProfessionalAccountDeactivated = "Hephaistos::ProfessionalAccount::Deactivated",
  ProfessionalAccountCreatedByAdmin = "Hephaistos::ProfessionalAccount::CreatedByAdmin",
  TeamCreated = "Hephaistos::Team::TeamCreated",
  TeamUpdated = "Hephaistos::Team::TeamUpdated",
  TeamDeleted = "Hephaistos::Team::TeamDeleted",
  TeamMemberAdded = "Hephaistos::Team::TeamMemberAdded",
  TeamMemberRemoved = "Hephaistos::Team::TeamMemberRemoved",
  TeamRoleGranted = "Hephaistos::Team::RoleGranted",
  TeamActivated = "Hephaistos::Team::Activated",
  TeamDeactivated = "Hephaistos::Team::Deactivated",
  TeamVisibilityEnabled = "Hephaistos::Team::VisibilityEnabled",
  TeamVisibilityDisabled = "Hephaistos::Team::VisibilityDisabled",
  TeamInvitationCreated = "Hephaistos::Team::InvitationCreated",
  HealthProfileValidated = "Hephaistos::HealthRecord::HealthProfile::Validated",
  HealthProfileValidatorRemoved = "Hephaistos::HealthRecord::HealthProfile::ValidatorRemoved",
  Unknown = "Unknown",
}
