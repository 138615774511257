import {
  Button,
  FormControl,
  MenuItem,
  Stack,
  SelectChangeEvent,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../hooks/useI18n";
import {
  CreateDocumentBodyDocumentCategoryEnum,
  DocumentCategoryEnum,
  Document,
  UpdateDocumentBodyDocument,
} from "@syadem/kairos-pro-js";
import { Dayjs } from "dayjs";
import { dayjs, localizedPlaceholder } from "../../../../utils/dayjs";
import { LightStyledSelect } from "../../../components/mui/LightStyledSelect";
import { LightStyledInput } from "../../../components/mui/LightStyledInput";
import LightStyledDatepicker from "../../../components/mui/LightStyledDatepicker";
import { useServiceBus } from "../../../hooks/useServiceBus";
import { useAppContext } from "../../../hooks/useAppContext";
import { FileUploadZone } from "./FileUploadZone";

interface DocumentFormProps {
  onClose: () => void;
  existingDocument?: Document;
  hideFileUpload?: boolean;
  documents: Document[];
}
// Helper function to format file sizes
const formatFileSize = (bytes: number): string => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
  return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
};

const MAX_DATE = dayjs.utc()
const MIN_DATE = dayjs.utc().subtract(122, "year")

export function DocumentForm({ onClose, existingDocument, hideFileUpload = false, documents }: DocumentFormProps) {
  const { id } = useParams() as { id: string };
  const { t, locale } = useI18n();
  const { organizationId, teamId } = useAppContext();
  const [isSending, setIsSending] = useState(false);
  const [fileError, setFileError] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const serviceBus = useServiceBus();

  const formatDefaultDocumentName = useCallback(
    (category: DocumentCategoryEnum) => {
      return `${dayjs().format("YYYYMMDD")} - ${t(`documents.attributes.categories.${category}`)}`;
    },
    [t],
  );

  const isVaccinationBookCategoryAllowed = useMemo(() => {
    const alreadyHasVaccinationBookDocument = documents?.some((doc) => doc.category === CreateDocumentBodyDocumentCategoryEnum.VaccinationBook)
    const isEditingVaccinationBookDocument = existingDocument?.category === CreateDocumentBodyDocumentCategoryEnum.VaccinationBook
    return isEditingVaccinationBookDocument || !alreadyHasVaccinationBookDocument
  },
    [documents, existingDocument?.category]
  );
  const [files, setFiles] = useState<Array<File & { isValid?: boolean }>>([]);
  const [documentCategory, setDocumentCategory] = useState<DocumentCategoryEnum>(
    existingDocument?.category
    || (isVaccinationBookCategoryAllowed
      ? CreateDocumentBodyDocumentCategoryEnum.VaccinationBook
      : CreateDocumentBodyDocumentCategoryEnum.Letter),
  );
  const [documentName, setDocumentName] = useState(
    existingDocument?.name || formatDefaultDocumentName(documentCategory),
  );
  const [date, setDate] = useState<Dayjs | null>(existingDocument?.date ? dayjs(existingDocument.date) : null);

  const initialDate = existingDocument?.date ? dayjs(existingDocument.date) : null; // track initial date in edit mod

  const hasChanged = useCallback(() => {
    if (!existingDocument) return true; // In create mode, always allow submission

    return (
      documentName !== existingDocument.name ||
      documentCategory !== existingDocument.category ||
      !((initialDate === null && date === null) || (initialDate && date && initialDate.isSame(date, "day")))
    );
  }, [documentName, documentCategory, date, existingDocument, initialDate]);

  const isFormValid = useCallback(() => {
    const isValidDate = date ? (date.isValid() && (date.isSame(MIN_DATE) || date.isAfter(MIN_DATE)) && (date.isSame(MAX_DATE) || date.isBefore(MAX_DATE))) : true;
    const hasRequiredFiles = existingDocument || files.length > 0;
    const hasInvalidFiles = files.some((file) => file.isValid === false);
    return isValidDate && documentName.length > 0 && hasRequiredFiles && !hasInvalidFiles && !fileError;
  }, [date, documentName, files, existingDocument, fileError]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!existingDocument && !files.length) {
      throw new Error("Invalid form");
    }

    try {
      setIsSending(true);

      if (existingDocument) {
        const updatedDocument: UpdateDocumentBodyDocument = {};
        if (documentName !== existingDocument.name) {
          updatedDocument.name = documentName;
        }
        if (documentCategory !== existingDocument.category) {
          updatedDocument.category = documentCategory;
        }
        if (!((initialDate === null && date === null) || (initialDate && date && initialDate.isSame(date, "day")))) {
          updatedDocument.date = date ? date.toDate() : null;
        }

        const result = await serviceBus.dispatch({
          type: "updateDocument",
          healthRecordId: id,
          documentId: existingDocument.id,
          document: updatedDocument,
          organizationId: organizationId,
          teamId: teamId,
        });

        if (result.type === "error") {
          throw new Error(result.message);
        }
      } else {
        const validFiles = files.filter((file) => file.isValid !== false);

        const totalSize = validFiles.reduce((sum, file) => sum + file.size, 0);
        const totalSizeFormatted = formatFileSize(totalSize);

        const createResult = await serviceBus.dispatch({
          type: "createDocumentAndAttachFiles",
          healthRecordId: id,
          document: {
            name: documentName,
            category: documentCategory,
            date: date ? date.toDate() : undefined,
          },
          organizationId: organizationId,
          teamId: teamId,
          attachments: validFiles,
          onProgressUpdate: (progress: number) => {
            setUploadProgress(progress);
            const uploadedBytes = Math.round(totalSize * progress);
            setUploadStatus(`Uploading: ${formatFileSize(uploadedBytes)} of ${totalSizeFormatted}`);
          },
        });

        if (createResult.type === "error") {
          throw new Error(createResult.message);
        }
      }

      onClose();
    } catch (error) {
      console.error("Error in document operation:", error);
      if (error instanceof Error) {
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
        });
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleSelectCategory = (e: SelectChangeEvent<string>) => {
    const newCategory = e.target.value as DocumentCategoryEnum;
    setDocumentCategory(newCategory);

    // Only update name when creating a new document and the user hasn't manually changed the name
    if (!existingDocument && documentName === formatDefaultDocumentName(documentCategory)) {
      setDocumentName(formatDefaultDocumentName(newCategory));
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Stack spacing={2} sx={{ minWidth: { xs: "100%", md: 350 }, pt: 1 }}>
        <FormControl fullWidth size="small" required>
          <LightStyledSelect
            label={t("documents.attributes.category")}
            name="category"
            value={documentCategory}
            renderValue={(category) => t(`documents.attributes.categories.${category}`)}
            required
            onChange={handleSelectCategory}
          >
            {Object.values(CreateDocumentBodyDocumentCategoryEnum)
              .filter(category => isVaccinationBookCategoryAllowed
                ? true
                : category !== CreateDocumentBodyDocumentCategoryEnum.VaccinationBook
              )
              .map((category) => (
                <MenuItem key={category} value={category}>
                  {t(`documents.attributes.categories.${category}`)}
                </MenuItem>
              ))}
          </LightStyledSelect>
        </FormControl>

        <LightStyledInput
          label={t("documents.attributes.name")}
          name="name"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          required
        />

        <LightStyledDatepicker
          fullWidth
          label={t("documents.attributes.date")}
          maxDate={MAX_DATE}
          minDate={MIN_DATE}
          name="date"
          value={date}
          placeholder={localizedPlaceholder(locale, t)}
          onChange={(date) => setDate(date ? date : null)}
        />

        {!hideFileUpload && (
          <FileUploadZone files={files} setFiles={setFiles} fileError={fileError} setFileError={setFileError} />
        )}

        {isSending && !existingDocument && (
          <Box sx={{ width: "100%", mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 0.5 }}>
              <Typography variant="body2" color="text.secondary">
                {uploadStatus}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {Math.round(uploadProgress * 100)}%
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={uploadProgress * 100}
              sx={{
                height: 6,
                borderRadius: 1,
                "& .MuiLinearProgress-bar": {
                  transition: "transform 0.1s linear",
                },
              }}
            />
          </Box>
        )}

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ pt: 2 }}>
          <Button variant="outlined" onClick={onClose} disabled={isSending}>
            {t("common.cta.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            loading={isSending}
            disabled={isSending || !isFormValid() || (existingDocument && !hasChanged())}
          >
            {t("common.cta.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
