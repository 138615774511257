import { DocumentApiInterface } from "@syadem/kairos-team-js";
import { AppStore } from "../store";

export class TeamDocumentsQuery {
  constructor(
    private readonly store: AppStore,
    private readonly documentApi: DocumentApiInterface,
  ) { }

  async call(
    { organizationId, teamId, healthRecordId }:
      {
        organizationId: string,
        teamId: string,
        healthRecordId: string
      }
  ) {
    const { documents } = await this.documentApi.indexDocuments(organizationId, teamId, healthRecordId);

    this.store.setState((state) => {
      if (state.healthRecords) {
        state.healthRecords[healthRecordId].documents = documents
      }
      return state;
    });
  }
}
