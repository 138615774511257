import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Avatar, Box, Button, FormControlLabel, Radio, RadioGroup, Typography, useMediaQuery } from "@mui/material";
import { VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import { VaccinationAct as TeamVaccinationAct } from "@syadem/kairos-team-js";
import { useCallback, useState } from "react";
import { calculateAge } from "../../utils/ageCalculator";
import { dayjs, localizedDate } from "../../utils/dayjs";
import { useDaphne } from "../hooks";
import { useAppContext } from "../hooks/useAppContext";
import { useI18n } from "../hooks/useI18n";
import { useServiceBus } from "../hooks/useServiceBus";
import { theme } from "../layout/Theme";
import { Vaccine } from "./Vaccine";
import { IconMedicalInstrumentSyringe } from "./icons/IconMedicalInstrumentSyringe";
import StyledDialog from "./mui/StyledDialog";

interface VaccinationActCertifyButtonProps {
  vaccinationAct: ProVaccinationAct | TeamVaccinationAct;
  birthDate: Date;
}

export function VaccinationActCertifyButton({ vaccinationAct, birthDate }: VaccinationActCertifyButtonProps) {
  const { t, locale } = useI18n();
  const daphne = useDaphne();
  const serviceBus = useServiceBus();
  const { organizationId, teamId } = useAppContext();

  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [certificationDialogOpen, setCertificationDialogOpen] = useState<boolean>(false);
  const [certificationMethod, setCertificationMethod] = useState<string | undefined>(
    dayjs(vaccinationAct?.performedOn).isBefore(dayjs(), "day") ? "proof" : "execution",
  );
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const handleClickOpen = () => {
    setCertificationDialogOpen(true);
  };

  const handleClose = () => {
    setCertificationDialogOpen(false);
  };

  const certifyHandler = useCallback(async () => {
    if (certificationMethod != undefined) {
      setErrorMessage(undefined);
      setIsSending(true);
      try {
        serviceBus.dispatch({
          type: "certifyVaccinationAct",
          organizationId,
          teamId,
          certificationMethod,
          healthRecordId: vaccinationAct.healthRecordId,
          vaccinationActId: vaccinationAct.id,
        });

        setCertificationDialogOpen(false);
      } catch (_e) {
        setErrorMessage(t("common.alerts.alert_notification"));
      } finally {
        setIsSending(false);
      }
    }
  }, [certificationMethod, vaccinationAct, t, serviceBus, organizationId, teamId]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<TaskAltOutlinedIcon />}
        disableElevation
        sx={{ width: { xs: "auto", md: "110px" } }}
        size={breakpointMdUp ? "medium" : "small"}
      >
        {t("common.cta.validate")}
      </Button>
      <StyledDialog
        open={certificationDialogOpen}
        onClose={handleClose}
        title={t("vaccines.validate")}
        errorMessage={errorMessage}
        dialogActions={
          <Button onClick={certifyHandler} autoFocus variant="contained" loading={isSending}>
            {t("common.cta.validate")}
          </Button>
        }
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            border: `solid 1px ${theme.palette.neutral[300]}`,
            borderRadius: 1,
            mb: 3,
          }}
        >
          <Avatar
            sx={{
              background: theme.palette.primary[100],
              width: { xs: 40, sm: 48 },
              height: { xs: 40, sm: 48 },
              mr: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.primary[500],
                ".iconSyringe": {
                  height: "24px !important",
                },
              }}
            >
              <IconMedicalInstrumentSyringe />
            </Box>
          </Avatar>
          <div>
            {daphne && <Vaccine vaccineId={vaccinationAct.vaccineId} daphne={daphne} />}
            {vaccinationAct.batchNumber && (
              <Typography variant="body2" color={theme.palette.neutral[600]} component="p">
                {`${t("vaccines.batch_number")}: ${vaccinationAct.batchNumber}`}
              </Typography>
            )}
            <Typography variant="body2" color={theme.palette.neutral[500]} component="p">
              {t("vaccines.administration", {
                date: localizedDate(vaccinationAct.performedOn, locale),
                age: calculateAge(vaccinationAct?.performedOn, birthDate, true, t),
              })}
            </Typography>
          </div>
        </Box>
        <RadioGroup
          aria-labelledby="certification-radio-buttons"
          value={certificationMethod}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCertificationMethod((event.target as HTMLInputElement).value);
          }}
          name="certification-radio-buttons"
        >
          <FormControlLabel
            value="proof"
            control={<Radio size="small" />}
            label={<Typography variant="body2">{t("vaccines.confirmation.validate")}</Typography>}
          />
          <FormControlLabel
            value="execution"
            control={<Radio size="small" />}
            label={<Typography variant="body2">{t("vaccines.confirmation.do_validate")}</Typography>}
          />
        </RadioGroup>
      </StyledDialog>
    </>
  );
}
