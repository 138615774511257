import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Skeleton, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { LanguageSelector } from "../components/LanguageSelector";
import { useDrawerToggleHandler } from "./Layout";
import { theme } from "./Theme";
import HelpCenterMenu from "./HelpCenterMenu";

export default function Header({
  children,
  tabs,
  actions,
  centerActions,
}: {
  children?: React.ReactNode;
  tabs?: React.ReactNode;
  actions?: React.ReactNode;
  centerActions?: React.ReactNode;
}) {
  const handleDrawerToggle = useDrawerToggleHandler();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar color="inherit" position="sticky" elevation={0}>
      <Toolbar sx={{ py: { xs: 1, md: 3 }, px: { xs: 0, md: 3 } }}>
        <Grid2
          width="100%"
          container
          alignItems="center"
          justifyContent="space-between"
          color={theme.palette.neutral[600]}
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid2 width="100%" container spacing={1} alignItems="center">
            <Grid2 size={1} sx={{ display: { lg: "none", xs: "block" } }}>
              <IconButton aria-label="open drawer" onClick={handleDrawerToggle} edge="start">
                <MenuIcon />
              </IconButton>
            </Grid2>
            <Grid2 size={11}>
              <Typography variant={breakpointSm ? "h6" : "h5"} component="h1">
                {children ?? <Skeleton variant="text" />}
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
        {!breakpointSm && (
          <Box sx={{ display: "inline-flex", alignItems: "center" }}>
            {actions}
            <HelpCenterMenu />
            <LanguageSelector />
          </Box>
        )}
      </Toolbar>
      {centerActions && (
        <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, backgroundColor: "inherit" }}>
          {centerActions}
        </AppBar>
      )}
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, backgroundColor: "inherit" }}>
        {tabs}
      </AppBar>
    </AppBar>
  );
}
