import SyncIcon from "@mui/icons-material/Sync";
import { Alert, Box, Button, LinearProgress, MenuItem, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useProHealthRecords, useTeamHealthRecords, useTeams } from "../../store";
import { EmptyListPlaceholder } from "../components/shared/EmptyListPlaceholder";
import { useFetchAllHealthRecords } from "../hooks";
import { useAppContext } from "../hooks/useAppContext";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";
import { useApis, useQueries } from "../providers/Dependencies";

export function HealthRecordsTransfer() {
  const { t } = useI18n();
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const teams = useTeams();
  const { allOrganizationsQuery } = useQueries();
  useFetchAllHealthRecords();
  const proHealthRecords = useProHealthRecords();
  const { healthRecords: teamHealthRecords } = useTeamHealthRecords(teamId);
  const [destinationTeamId, setDestinationTeamId] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [successMessageDisplayed, setSuccessMessageDisplayed] = useState<boolean>(false);
  const [errorsCount, setErrorsCount] = useState<number>(0);
  const [processedRecordsCount, setProcessedRecordsCount] = useState<number>(0);
  const healthRecords = useMemo(
    () => (organizationId && teamId ? teamHealthRecords : proHealthRecords),
    [organizationId, teamId, teamHealthRecords, proHealthRecords],
  );

  useEffect(() => {
    allOrganizationsQuery.call();
  }, [allOrganizationsQuery]);

  const transferHealthRecords = useCallback(async () => {
    if (healthRecords && destinationTeamId) {
      setIsSubmitting(true);
      setSuccessMessageDisplayed(false);
      setProcessedRecordsCount(0);
      setErrorsCount(0);

      for (const healthRecord of healthRecords) {
        try {
          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.healthRecordApi.share(organizationId, teamId, healthRecord.id, {
              team: { id: destinationTeamId },
            });
          } else {
            await apis.pro.healthRecordApi.share(healthRecord.id, { team: { id: destinationTeamId } });
          }
        } catch (_error) {
          setErrorsCount((prevValue) => prevValue + 1);
        } finally {
          setProcessedRecordsCount((prevValue) => prevValue + 1);
        }
      }

      setIsSubmitting(false);
      setSuccessMessageDisplayed(true);
    }
  }, [apis, healthRecords, destinationTeamId, organizationId, teamId]);

  const progressValue = useCallback(() => {
    return healthRecords ? Math.round((processedRecordsCount / healthRecords.length) * 100) : 0;
  }, [healthRecords, processedRecordsCount]);

  return (
    <PageLayout title={t("common.interface.health_records_transfer")}>
      <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
        {teams ? (
          Object.keys(teams).length > 0 ? (
            <>
              <Alert severity="info" sx={{ maxWidth: "sm", marginX: "auto", mb: 2 }}>
                {t("healthRecordsTransfer.guidanceOne")}
                <br />
                {t("healthRecordsTransfer.guidanceTwo")}
                <br />
                {t("healthRecordsTransfer.guidanceThree")}
              </Alert>
              <Paper
                sx={{
                  maxWidth: "sm",
                  marginX: "auto",
                  overflow: "hidden",
                  p: 4,
                  border: `solid 1px ${theme.palette.neutral[200]}`,
                }}
                elevation={0}
              >
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t("healthRecordsTransfer.healthcareTeam")}
                    value={destinationTeamId}
                    onChange={(e) => setDestinationTeamId(e.target.value)}
                    sx={{ mr: 2 }}
                  >
                    {Object.entries(teams).map(([_, team]) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button variant="contained" size="small" onClick={transferHealthRecords} loading={isSubmitting}>
                    {t("common.cta.validate")}
                  </Button>
                </Box>
                {isSubmitting && (
                  <>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress variant="determinate" value={progressValue()} />
                      </Box>
                      <Box>
                        <Typography>{`${progressValue()}%`}</Typography>
                      </Box>
                    </Box>
                    <Typography color={theme.palette.neutral[500]} fontSize="0.85rem">
                      {t("healthRecordsTransfer.inProgressGuidance")}
                    </Typography>
                  </>
                )}
              </Paper>
            </>
          ) : (
            <EmptyListPlaceholder
              title={t("healthRecordsTransfer.noTeamTitle")}
              icon={<SyncIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
            >
              {t("healthRecordsTransfer.noTeamDescription")}
            </EmptyListPlaceholder>
          )
        ) : null}
      </Paper>
      <Snackbar
        open={successMessageDisplayed}
        autoHideDuration={5000}
        onClose={() => setSuccessMessageDisplayed(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {errorsCount > 0 ? (
          <Alert severity="warning">{t("healthRecordsTransfer.successMessageWithErrorsCount", { errorsCount })}</Alert>
        ) : (
          <Alert severity="success">{t("healthRecordsTransfer.successMessage")}</Alert>
        )}
      </Snackbar>
    </PageLayout>
  );
}
