import { Configuration as ProConfiguration } from "@syadem/kairos-pro-js";
import { Configuration as TeamConfiguration } from "@syadem/kairos-team-js";

export interface ProKairosCertApiInterface {
  getVaccinationCertificate(healthRecordId: string, extended: boolean): Promise<Blob>;
}

export class ProKairosCertApi implements ProKairosCertApiInterface {
  constructor(
    public readonly configuration: ProConfiguration,
    public readonly kairosCertBasePath: string
  ) {}

  async getVaccinationCertificate(healthRecordId: string, extended: boolean): Promise<Blob> {
    if (this.configuration.accessToken === undefined) throw new Error("Access token undefined");

    const token = await this.configuration.accessToken();
    const path = `/private/pro/health_records/${healthRecordId}`;
    const url = extended ? `${this.kairosCertBasePath}${path}?extended=true` : `${this.kairosCertBasePath}${path}`;
    const locale = localStorage.getItem("proLocale");
    const proLocale = JSON.parse(locale as string) === "en-gb" ? "en" : locale;
    const response = await fetch(url, { headers: { Token: token, "Accept-Language": proLocale ?? "en" } });

    const blob = await response.blob();

    return blob;
  }
}

export interface TeamKairosCertApiInterface {
  getVaccinationCertificate(healthRecordId: string, organizationId: string, teamId: string, extended: boolean): Promise<Blob>;
}

export class TeamKairosCertApi implements TeamKairosCertApiInterface {
  constructor(
    public readonly configuration: TeamConfiguration,
    public readonly kairosCertBasePath: string
  ) {}

  async getVaccinationCertificate(healthRecordId: string, organizationId: string, teamId: string, extended: boolean): Promise<Blob> {
    if (this.configuration.accessToken === undefined) throw new Error("Access token undefined");

    const token = await this.configuration.accessToken();
    const path = `/private/team/organizations/${organizationId}/teams/${teamId}/health_records/${healthRecordId}`;
    const url = extended ? `${this.kairosCertBasePath}${path}?extended=true` : `${this.kairosCertBasePath}${path}`;
    const locale = localStorage.getItem("proLocale");
    const proLocale = JSON.parse(locale as string) === "en-gb" ? "en" : locale;
    const response = await fetch(url, { headers: { Token: token, "Accept-Language": proLocale ?? "en" } });

    const blob = await response.blob();

    return blob;
  }
}