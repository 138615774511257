import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ShareIcon from "@mui/icons-material/Share";
import { Alert, Avatar, Box, Button, LinearProgress, List, Paper, Typography } from "@mui/material";
import { SharingRequestStatusEnum } from "@syadem/kairos-pro-js";
import { Link, useLocation, useParams } from "react-router-dom";
import { DomainHealthRecord } from "../../../domain/healthRecord";
import { User } from "../../../domain/user";
import { useAuthenticatedUser, useHealthRecord } from "../../../store";
import { isSharedToPatient } from "../../../utils/healthRecord";
import { ProShareListItem } from "../../components/ProShareListItem";
import { SentSharingRequestListItem } from "../../components/SentSharingRequestListItem";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { TeamShareListItem } from "../../components/TeamShareListItem";
import { useFetchHealthRecord } from "../../hooks/useFetchHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";

const isSharedWithProOrTeam = (hr: DomainHealthRecord, currentUser: User) => {
  return (
    hr.sharedWithTeams.length > 0 ||
    hr.sharedWithPro.filter((pro) => pro.id != currentUser.id)?.length > 0 ||
    hr.sharingRequests.filter((req) => req.status == "PENDING").length > 0
  );
};

export function Shares() {
  const { id } = useParams() as { id: string };
  const { isLoading, error, refetch } = useFetchHealthRecord(id);
  const healthRecord = useHealthRecord(id);

  const { t } = useI18n();
  const currentUser = useAuthenticatedUser();
  const { pathname } = useLocation();

  if (error) {
    return (
      <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
        <Typography sx={{ my: 4, mx: 2 }} align="center" component="div">
          <Alert severity="error">{t("common.alerts.alert_notification")}</Alert>
        </Typography>
      </Paper>
    );
  }

  if (currentUser === undefined || healthRecord === undefined) {
    return (
      <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
        <LinearProgress variant="query" />
      </Paper>
    );
  }

  return (
    <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
      <Header />
      {isLoading ? <LinearProgress variant="query" /> : null}
      {isSharedWithProOrTeam(healthRecord, currentUser) && <SharingLists healthRecord={healthRecord} />}
      {!isSharedWithProOrTeam(healthRecord, currentUser) && <EmptyPlaceHolder />}
    </Paper>
  );

  function Header() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        {isSharedToPatient(healthRecord) ? (
          <Alert severity="success" sx={{ my: 1 }}>
            {t("health_records.patient_is_owner")}
          </Alert>
        ) : (
          <Alert severity="info" sx={{ my: 1 }}>
            {t("health_records.non_shared")}
          </Alert>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            startIcon={
              <Avatar
                sx={{
                  background: theme.palette.primary[500],
                  width: { xs: 24, md: 32 },
                  height: { xs: 24, md: 32 },
                }}
              >
                <AddIcon fontSize="small" sx={{ color: "white" }} />
              </Avatar>
            }
            sx={{
              ".MuiButton-startIcon": {
                minWidth: { xs: "40px", md: "56px" },
                m: 0,
              },
              p: 2,
            }}
            component={Link}
            to={pathname.replace("/shares", "/share")}
            disableElevation
            data-testid="share-health-record-button"
          >
            <Typography color="primary" fontWeight="500">
              {t("health_records.share_button_2")}
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  function EmptyPlaceHolder() {
    return (
      <EmptyListPlaceholder
        title={t("health_records.none_shared")}
        icon={<PersonAddIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
        button={
          <Button
            size="large"
            component={Link}
            to={pathname.replace("/shares", "/share")}
            variant="contained"
            startIcon={<ShareIcon />}
            disableElevation
            data-testid="share-health-record-button"
          >
            {t("health_records.share_button")}
          </Button>
        }
      >
        {isSharedToPatient(healthRecord) ? t("health_records.share_pros") : t("health_records.share_patients_and_pros")}
      </EmptyListPlaceholder>
    );
  }

  function SharingRequests(props: { healthRecord: DomainHealthRecord }) {
    const pendings = props.healthRecord.sharingRequests.filter(
      (sharing) => sharing.status === SharingRequestStatusEnum.Pending,
    );
    if (pendings.length === 0) {
      return <></>;
    }

    return (
      <>
        {pendings.map((request) => (
          <SentSharingRequestListItem
            key={request.id}
            healthRecord={props.healthRecord}
            sharingRequest={request}
            refetch={refetch}
          />
        ))}
      </>
    );
  }

  function TeamSharing(props: { healthRecord: DomainHealthRecord }) {
    return (
      <>
        {props.healthRecord.sharedWithTeams.map((team) => (
          <TeamShareListItem key={team.id} team={team} />
        ))}
      </>
    );
  }

  function ProSharing(props: { healthRecord: DomainHealthRecord }) {
    return (
      <>
        {props.healthRecord.sharedWithPro.map((pro) => (
          <ProShareListItem key={pro.id} pro={pro} />
        ))}
      </>
    );
  }

  function SharingLists(props: { healthRecord: DomainHealthRecord }) {
    return (
      <Box>
        <List sx={{ width: "100%", mb: 2 }}>
          <SharingRequests {...props} />
          <TeamSharing {...props} />
          <ProSharing {...props} />
        </List>
      </Box>
    );
  }
}
