import { VaccinationAct  as ProVaccinationAct} from "@syadem/kairos-pro-js";
import { VaccinationAct  as TeamVaccinationAct} from "@syadem/kairos-team-js";

export type DomainVaccinationAct = ProVaccinationAct & TeamVaccinationAct;

export interface Certificate {
  certificator: {
    id: string;
    type: "professional" | "team";
    teamMemberAccountId?: string;
  };
  certifiedOn: Date;
  certificationMethod: "execution" | "proof" | "not_validated";
};

export const diseaseGroupsFr = [
  {
    diseases: ["Diphtérie", "Tétanos", "Poliomyélite"],
    groupName: { fr: "Diphtérie, Tétanos, Poliomyélite", en: "Diphtheria, Tetanus, Poliomyelitis" },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche", "Poliomyélite"],
    groupName: {
      fr: "Diphtérie, Tétanos, Coqueluche, Poliomyélite",
      en: "Diphtheria, Tetanus, Pertussis, Poliomyelitis",
    },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche"],
    groupName: { fr: "Diphtérie, Tétanos, Coqueluche", en: "Diphtheria, Tetanus, Pertussis" },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche", "Poliomyélite", "Haemophilus influenzae b"],
    groupName: {
      fr: "Diphtérie, Tétanos, Coqueluche, Poliomyélite, Haemophilus influenzae b",
      en: "Diphtheria, Tetanus, Pertussis, Poliomyelitis, Haemophilus influenzae b",
    },
  },
  {
    diseases: ["Diphtérie", "Tétanos", "Coqueluche", "Poliomyélite", "Haemophilus influenzae b", "Hépatite B"],
    groupName: {
      fr: "Diphtérie, Tétanos, Coqueluche, Poliomyélite, Haemophilus influenzae b, Hépatite B",
      en: "Diphtheria, Tetanus, Pertussis, Poliomyelitis, Haemophilus influenzae b, Hepatitis B",
    },
  },
  {
    diseases: ["Rougeole", "Oreillons", "Rubéole"],
    groupName: { fr: "Rougeole, Oreillons, Rubéole", en: "Measles, Mumps, Rubella" },
  },
  { diseases: ["Hépatite A", "Hépatite B"], groupName: { fr: "Hépatites A et B", en: "Hepatitis A and B" } },
  {
    diseases: ["Méningocoque A", "Méningocoque C", "Méningocoque W", "Méningocoque Y"],
    groupName: { fr: "Méningocoques ACWY", en: "Meningococci ACWY" },
  },
  { diseases: ["Hépatite A", "Typhoïde"], groupName: { fr: "Hépatite A, Typhoïde", en: "Hepatitis A, Typhoid" } },
];

export function getCertificate(vaccinationAct: DomainVaccinationAct): Certificate | null {
  if (!(vaccinationAct.certificator && vaccinationAct.certificationMethod && vaccinationAct.certifiedOn)) { return null; }

  return {
    certificator: {
      id: vaccinationAct.certificator.id,
      type: vaccinationAct.certificator.type,
      teamMemberAccountId: vaccinationAct.certificator.teamMemberAccountId ?? undefined 
    },
    certificationMethod: vaccinationAct.certificationMethod,
    certifiedOn: vaccinationAct.certifiedOn
  };
}