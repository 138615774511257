import { Log as EventLogApi } from "@syadem/kairos-team-js";
import { Log, LogType } from "../../domain/log";

export function parseApiLog(log: EventLogApi): Log {
  const type = Object.values(LogType).includes(log.type as LogType) ? (log.type as LogType) : LogType.Unknown;

  return {
    id: log.id,
    emittedBy: log.emittedBy,
    rootId: log.rootId,
    type,
    data: log.data,
    createdAt: new Date(log.createdAt),
  };
}
