import { HealthRecordApi } from "@syadem/kairos-pro-js";
import { AppStore, updateHealthRecord } from "../store";

export class ProHealthRecordQuery {
  constructor(
    private readonly store: AppStore,
    private readonly healthRecordApi: HealthRecordApi,
  ) {}

  async call(id: string, teamId: string | null) {
    const healthRecord = (await this.healthRecordApi.getHealthRecord(id)).healthRecord;

    this.store.setState(updateHealthRecord(healthRecord, teamId));
  }
}
