import { AppStore } from "../store";
import { Service } from "./service";
import { ActionResult } from "../actionResult";
import { ProDocumentFileApiInterface } from "../network/apis/proDocumentFileApi";
import { TeamDocumentFileApiInterface } from "../network/apis/teamDocumentFileApi";

interface Payload {
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  documentId: string;
  attachment: {
    fileId: string;
    filename: string;
  };
}

export class DownloadDocumentFileService implements Service<Payload, Blob> {
  constructor(
    private readonly proDocumentFileApi: ProDocumentFileApiInterface,
    private readonly teamDocumentFileApi: TeamDocumentFileApiInterface,
    private readonly store: AppStore,
  ) {}

  async call({ healthRecordId, organizationId, teamId, documentId, attachment }: Payload): Promise<ActionResult<Blob>> {
    let blob: Blob;

    if (organizationId && teamId) {
      blob = await this.teamDocumentFileApi.downloadFile({
        organizationId,
        teamId,
        healthRecordId,
        documentId,
        attachment,
      });
    } else {
      blob = await this.proDocumentFileApi.downloadFile({ healthRecordId, documentId, attachment });
    }

    return {
      type: "success",
      value: blob,
    };
  }
}
