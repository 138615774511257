export const fr = {
  documents: {
    title: "Documents",
    no_documents: "Aucun document",
    add_document: "Ajouter un document",
    delete_document: "Supprimer le document",
    delete_confirmation_message: "Êtes-vous sur de vouloir supprimer ce document ?",
    add_files: "Ajouter des fichiers",
    edit_document: "Modifier un document",
    file_upload: {
      add_files: "Ajouter des fichiers",
      add_more_files: "Ajouter plus de fichiers",
      file_types_allowed_info: "PDF, PNG, JPEG, TIFF (max %{size})",
      file_type_error: "Type de fichier non supporté",
      file_size_error: "Le fichier dépasse la taille maximale de %{size}",
      total_size_error: "La taille totale des fichiers dépasse la taille maximale de %{size}",
      invalid_files: "Certains fichiers ne sont pas pris en charge",
      drop_files: "Déposez les fichiers",
      drag_drop_or_browse: "Glissez-déposez ou sélectionnez des fichiers",
      files_summary: "%{amount} fichier(s) • Total: %{totalSize}",
    },
    cta: {
      add: "Ajouter un document",
      add_msg: "Ajoutez des documents à ce carnet",
    },
    attributes: {
      name: "Nom",
      category: "Catégorie",
      date: "Date",
      attachments: "Fichiers",
      categories: {
        vaccination_book: "Carnet de vaccination",
        letter: "Courrier médical",
        parental_consent: "Autorisation parentale",
        biology: "Biologie",
        imagerie: "Imagerie",
        other: "Autre",
      },
    },
  },
  eventLogs: {
    title: "Journal d'événements",
    title_menu: "Journal d'événements",
    since: "Depuis le",
    until: "Jusqu'au",
    noOption: "Aucune option",
    eventLinkedTooltip: "Il s'agit des événements liés concernant le patient ou la consultation",
    noDetail: "Aucun détail",
    eventLinked: "Évènements liés",
    date: "Date",
    professional: "Professionnel",
    event: "Évènement",
    noLog: "Aucun log",
    "Hephaistos::HealthRecord::VaccinationAct::Created": "Ajout d'une vaccination",
    "Hephaistos::HealthRecord::VaccinationAct::Updated": "Mise à jour d'une vaccination",
    "Hephaistos::HealthRecord::VaccinationAct::Deleted": "Suppression d'une vaccination",
    "Hephaistos::HealthRecord::VaccinationAct::Certified": "Vaccination certifiée",
    "Hephaistos::HealthRecord::Created": "Carnet créé",
    "Hephaistos::HealthRecord::Updated": "Identité patient mis à jour",
    "Hephaistos::HealthRecord::Deleted": "Carnet supprimé",
    "Hephaistos::HealthRecord::AccessGranted": "Accès au carnet accordé",
    "Hephaistos::HealthRecord::OwnershipGranted": "Propriété du carnet transférée",
    "Hephaistos::HealthRecord::SharingRequest::Created": "Demande de partage créée",
    "Hephaistos::HealthRecord::SharingRequest::Accepted": "Demande de partage acceptée",
    "Hephaistos::HealthRecord::SharingRequest::Deleted": "Demande de partage supprimée",
    "Hephaistos::HealthRecord::SharingRequest::Rejected": "Demande de partage rejetée",
    "Hephaistos::HealthRecord::SharedWithTeam": "Carnet partagé avec l'équipe",
    "Hephaistos::HealthRecord::UnsharedWithTeam": "Partage du carnet avec l'équipe révoqué",
    "Hephaistos::HealthRecord::Unshared": "Partage du carnet révoqué",
    "Hephaistos::HealthRecord::HealthProfile::ConditionSet": "Condition ajoutée au profil santé",
    "Hephaistos::HealthRecord::HealthProfile::ConditionUnset": "Condition retirée du profil santé",
    "Hephaistos::Organization::RoleGranted": "Rôle accordé dans l'organisation",
    "Hephaistos::Organization::MemberAdded": "Membre ajouté à l'organisation",
    "Hephaistos::Organization::MemberRemoved": "Membre retiré de l'organisation",
    "Hephaistos::Team::TeamMemberAdded": "Membre ajouté à l'équipe",
    "Hephaistos::Team::TeamMemberRemoved": "Membre retiré de l'équipe",
    "Hephaistos::Team::RoleGranted": "Rôle accordé dans l'équipe",
    "Hephaistos::Team::VisibilityEnabled": "Visibilité de l'équipe activée",
    "Hephaistos::Team::VisibilityDisabled": "Visibilité de l'équipe désactivée",
    "Hephaistos::Team::InvitationCreated": "Invitation créée pour l'équipe",
    "Hephaistos::HealthRecord::HealthProfile::Validated": "Profil santé validé",
    "Hephaistos::HealthRecord::HealthProfile::ValidatorRemoved": "Validateur du profil santé retiré",
    Unknown: "Inconnu",
  },
  sharingRequest: {
    attributes: {
      shared_by: "Partagé par",
      shared_to: "Partagé à",
      health_record_id: "Patient",
    },
  },
  sharedWithTeam: {
    attributes: {
      team_id: "Partage avec l'équipe",
      from_team_id: "À partir de l'équipe",
      health_record_id: "Patient",
    },
  },
  healthProfileConditionSet: {
    attributes: {
      condition_id: "Condition",
      value: "Valeur",
    },
  },
  healthProfileValidatorRemoved: {
    attributes: {
      validator_id: "Validateur",
    },
  },
  unsharedWithPro: {
    attributes: {
      account_id: "Identité du professionnel",
      account_type: "Professionnel",
    },
  },
  vaccinationAct: {
    attributes: {
      health_record_id: "Patient",
      performed_on: "Date de l'acte",
      vaccinator_id: "Vaccinateur",
      vaccine_id: "Vaccin",
      creator_account_id: "Vaccinateur",
      batch_number: "Lot",
      serial_number: "Numéro de série",
      injection_method: "Méthode d'administration",
      injection_location: "Lieu d'administration",
      booster: "Rappel",
      patient_full_name: "Patient",
    },
    injection_method: {
      intramuscular: "Intramusculaire",
      subcutaneous: "Sous-cutanée",
      intradermal: "Intradermique",
      oral: "Orale",
      intranasal: "Intranasale",
    },
    injection_location: {
      left_deltoid: "Deltoïde gauche",
      right_deltoid: "Deltoïde droit",
      left_thigh: "Cuisse gauche",
      right_thigh: "Cuisse droite",
    },
    booster: {
      true: "Oui",
      false: "Non",
    },
  },
  certification: {
    attributes: {
      certified_on: "Date de certification",
      certification_method: {
        title: "Méthode de certification",
        execution: "Exécution",
        proof: "Preuve",
      },
      certificator_id: "Certificateur",
    },
    successMessage: "Vaccination certifiée",
  },
  healthRecord: {
    unknown: "Inconnu",
    attributes: {
      first_names: "Prénoms",
      last_name: "Nom de famille",
      birth_date: "Date de naissance",
      gender: "Genre",
    },
  },
  notifications: {
    unmanaged_error: "Une erreur inattendue s'est produite",
  },
  users: {
    pro: "Professionnel de santé",
    team: "Équipe de soin",
  },
  logoTitle: "Carnet de vaccination numérique",
  area: "Espace professionnel",
  injectionLocations: {
    left_deltoid: "Deltoïde gauche",
    right_deltoid: "Deltoïde droit",
    left_thigh: "Cuisse gauche",
    right_thigh: "Cuisse droite",
  },
  injectionMethods: {
    intramuscular: "Intramusculaire",
    subcutaneous: "Sous-cutanée",
    intradermal: "Intradermique",
    oral: "Orale",
    intranasal: "Intranasale",
  },
  sharingRequestStatus: {
    accepted: "Acceptée",
    pending: "En attente",
    rejected: "Déclinée",
  },
  patient: "Patients",
  pro: "Professionnel de santé",
  recommandations: {
    DiagnosticList: {
      infosMessage: "Le système d'aide à la décision vaccinale suit les recommandations des autorités de santé.",
    },
  },
  profile: {
    expandAll: "Tout déplier",
    shrinkAll: "Tout replier",
    closeQuestionnaireCta: "Fermer le questionnaire santé",
    noConditions: "Aucune caractéristique",
    addConditionCta: "Ajouter une caractéristique",
    shortTitle: "Profil",
    conditionsTitle: "Caractéristiques renseignées",
    questionnaireTitle: "Complétez ici le profil santé",
    deleteCondition: {
      title: "Supprimer la caractéristique",
      content: "Êtes-vous sûr de vouloir supprimer cette caractéristique ?",
    },
    validate: "Valider le profil",
    validated: "Profil validé",
    validatedBy: "Validé par %{certificator}",
    validatedByAPro: "Validé par un professionnel de santé",
  },
  notes: {
    title: "Notes",
    newNote: "Nouvelle note",
    internalDescriptionPro: "Uniquement visible par vous",
    internalDescriptionTeam: "Visible par les membres de l'équipe %{teamName}",
    publicDescription: "Visible par toutes les personnes ayant accès à ce carnet",
    internal: "Interne",
    public: "Partagée",
    private: "Privée",
    unknownAuthor: "Auteur inconnu",
    deleteModalTitle: "Supprimer la note",
    deleteModalContent: "Êtes-vous sûr(e) de vouloir supprimer cette note ?",
    editContent: "Modifier le contenu",
    cancelModifications: "Annuler les modifications",
    lastModified: "Modifiée il y a %{distance}",
    addedAt: "Ajoutée il y a %{distance}",
    addANote: "Ajouter une note",
    newInternalNote: "Nouvelle note interne",
    newPublicNote: "Nouvelle note partagée",
    newPrivateNote: "Nouvelle note privée",
    cancelForm: "Annuler la saisie",
  },
  organization: {
    address: "Adresse",
    noAddress: "Non renseigné",
    name: "Nom de l'organisation",
    namePlaceholder: "ex: CHU de Bordeaux",
    creationGuidanceOne: "Saisissez les coordonnées de votre structure.",
    creationGuidanceTwo: "Vous pourrez par la suite créer des équipes au sein de cette nouvelle organisation.",
    phoneNumber: "Numéro de téléphone",
    noPhoneNumber: "Non renseigné",
    teamActivationPending:
      "L'ajout de l'équipe %{name} sera validé par un administrateur de MesVaccins.net dans les plus brefs délais.",
    settings: {
      title: "Paramètres",
      tabs: {
        subscription: "Abonnement",
        teams: "Équipes",
        info: "Infos",
      },
    },
  },
  healthRecordsTransfer: {
    noTeamTitle: "Aucune équipe de soin",
    noTeamDescription:
      "Vous devez être rattaché(e) à au moins une équipe de soin afin de pouvoir transférer vos carnets vers celle-ci.",
    guidanceOne:
      "Sélectionnez l'équipe de soin vers laquelle vous souhaitez transférer l'intégralité de votre patientèle.",
    guidanceTwo: "Les carnets seront toujours accessibles via le Mode Pro individuel.",
    guidanceThree: "Les carnets déjà partagés avec l'équipe en question seront automatiquement ignorés.",
    healthcareTeam: "Équipe de soin",
    successMessage: "Carnets transférés avec succès",
    successMessageWithErrorsCount:
      "Transfert de carnets terminé: %{errorsCount} carnet(s) n'ont pas pu être transférés",
    inProgressGuidance: "Ne quittez pas la page tant que le processus n'est pas terminé.",
  },
  common: {
    paidfeature:
      "A compter du 8 Janvier 2024, cette fonctionnalité sera uniquement accessible aux comptes professionnels ou aux équipes ayant souscrit à l'abonnement premium.",
    orgaPaidfeature:
      "A compter du 8 Janvier 2024, un abonnement premium sera requis pour pouvoir accéder aux carnets de l'équipe.",
    whySuscribe: "Pourquoi s’abonner à la version complète ?",
    unlockPaidFeatures: "Débloquez toutes les fonctionnalités avec le compte premium.",
    helpCenter: "Centre d'aide",
    createHelpTicket: "Nous contacter",
    and: "et",
    others: "Autres",
    loading: "Chargement...",
    navbar: {
      title: "Recommandations vaccinales",
      subtitle: "Pour vous et vos proches",
    },
    copied: "Copié !",
    resultsCount:
      "%{smart_count} résultat affiché sur %{totalCount} |||| %{smart_count} résultats affichés sur %{totalCount}",
    printing: "Impression",
    cta: {
      previous: "Précédent",
      back: "Retour",
      next: "Suivant",
      save: "Enregistrer",
      add: "Ajouter",
      edit: "Modifier",
      delete: "Supprimer",
      cancel: "Annuler",
      print: "Imprimer",
      close: "Fermer",
      reset: "Réinitialiser",
      submit: "Soumettre",
      savePdf: "PDF",
      validate: "Valider",
      logout: "Se déconnecter",
      accept: "Accepter",
      reject: "Refuser",
      remove: "Retirer",
      search: "Rechercher",
      view: "Consulter",
      subscribe: "Passer au compte premium",
      enter: "Saisir",
    },
    errors: {
      title: "Erreur %{statusCode}",
      title_unknown: "Erreur inconnue",
      404: "La page demandée n'existe pas.",
      401: "Vous n'avez pas l'autorisation d'accéder à cette page.",
      default: "Une erreur s'est produite, notre équipe technique a été prévenue.",
      noResults: "Aucun résultat",
      securityNumber: "Numéro de sécurité social invalide",
      zipCode: "Code postal invalide",
      birthDate: "Date de naissance invalide"
    },
    gender: {
      m: "Masculin",
      f: "Féminin",
      s: "Sexe",
    },
    conditions: {
      at: "à",
      equalTo: "est égal à",
    },
    dates: {
      "DD/MM/YYYY": "JJ/MM/AAAA",
      "DD.MM.YYYY.": "JJ.MM.AAAA.",
      "MM/DD/YYYY": "MM/JJ/AAAA",
      bornThe: "né(e) le %{birthDate}",
      born: {
        male: "né",
        female: "née",
      },
      day: "jour |||| jours",
      month: "mois |||| mois",
      week: "semaine |||| semaines",
      year: "an |||| ans",
      toDoIn: "A faire dans",
      toDoAsap: "A faire dès que possible",
      lastModified: "Modifié il y a %{distance}",
      addedAt: "Ajouté il y a %{distance}",
      processedAt: "Traité il y a %{distance}",
    },
    interface: {
      language: "langage",
      my_practice: "Mode Pro individuel",
      teamAccounts: "Mode Pro en équipe |||| Mode Pro en équipe",
      patients: "Patientèle",
      unshared_health_records: "Carnets archivés",
      pending_sharing_requests: "Partages en attente",
      informations: "Mon compte",
      sort_by: "Trier par",
      back_to_home: "Retour à l'accueil",
      reconnect: "Me reconnecter",
      organizations: "organisations",
      premium: "Compte premium",
      basic: "Compte basique",
      health_records_transfer: "Transfert de carnets",
      newTeam: "Nouvelle équipe",
      pendingTeamActivation: "En attente d'activation",
      newOrganization: "Nouvelle organisation",
      subscription: "Abonnement",
      subscriptionRequired: "Abonnement requis",
      paymentMethodRequired: "Moyen de paiement requis",
      organizationSettings: "Paramètres",
    },
    user_infos: {
      title: "Nous vous invitons à vérifier l’exactitude des informations fournies",
      first_names: "Prénom(s)",
      first_name: "Prénom",
      last_name: "Nom de naissance",
      customary_name: "Nom d'usage",
      birth_date: "Date de naissance",
      social_security_number: "N° sécurité sociale",
      social_security_number_infos:
        "Veuillez renseigner le numero de sécurité sociale pour préremplir le sexe, le mois et l'année de naissance",
      phone: "Téléphone",
      address: "Adresse",
      zip_code: "Code postal du lieu de résidence",
      city: "Ville",
      country: "Pays",
      placeholder: "Rechercher par nom, date de naissance et/ou NIR",
      team_placeholder: "Rechercher par nom",
      birth_country: "Pays de naissance",
      birth_place: "Lieu de naissance",
      birth_zip_code: "Code postal de naissance",
      subscription: {
        paid_by_pro: "Compte premium payé par le professionnel : %{name}",
        paid_by_organization: "Compte premium payé par l'organisation : %{name}",
        valid_until: "Date de fin d'abonnement : %{date}",
      },
    },
    alerts: {
      alert_notification: "Une erreur s'est produite, notre équipe technique a été prévenue.",
      alert_classic: "Une erreur s'est produite",
      alert_profile: "Votre profil a été mis à jour.",
      alert_auth: "Une erreur s'est produite lors de l'authentification, notre équipe technique a été prévenue.",
      alert_auth_unknown_error: "Erreur d'authentification",
      alert_logout: "Vous êtes déconnecté",
      alert_logout_long: "Votre session s'est terminée, veuillez vous reconnecter.",
      alert_saved_success: "Modifications enregistrées",
      alert_auth_failed: "Échec de l'authentification",
    },
    add_team: "Ajouter une équipe",
    city: {
      placeholder: "ex: Bordeaux ou 33000",
    },
    retry: "Réessayer",
  },
  payment: {
    title: "Abonnement requis",
    description_1:
      "Afin de bénéficier pleinement de toutes les fonctionnalités de MesVaccins.net, un abonnement est requis.",
    description_2: "Cet abonnement vous donnera accès :",
    description_3: "- à l’aide à la décision vaccinale",
    description_4: "- à l’outil de diagnostic vaccinal rapide sans création de carnet (Mentor Pro)",
    description_5: "- à la gestion des carnets de vaccination numériques en équipe",
    description_6: "Le coût de l'abonnement est de 299 € TTC par an.",
    description_7: "Contactez-nous via le centre d'aide dans les cas suivants :",
    description_8: "- Vous exercez en Nouvelle-Aquitaine",
    description_9: "- Vous êtes pharmacien d'officine en Auvergne-Rhône-Alpes",
    error: "Une erreur sur le paiement est survenue, si l'erreur persiste merci de contacter le support.",
    freeTrial: "Créez votre organisation afin de bénéficier de 30 jours d'essai gratuit sans engagement",
    howToSubscribe: "Les administrateurs d'une organisation peuvent gérer l'abonnement via le menu éponyme",
    diagnosticDetails:
      "Statut vaccinal en fonction de l'historique vaccinal, du profil santé et des recommandations vaccinales en vigueur (à jour, à faire, date de la prochaine échéance, contre-indication), justification de la recommandation, vaccins utilisables, professionnels autorisés à prescrire ou vacciner et sources",
  },
  payment_success: {
    title: "Merci !",
    description_1: "Merci pour votre inscription, vous pouvez désormais utiliser toutes les fonctionnalitées.",
  },
  paywall: {
    title: "Compte premium requis",
    description_1:
      "Cette fonctionnalité est uniquement accessible aux comptes professionnels ou aux équipes ayant un compte Premium.",
    cta: "Passer au compte premium",
  },
  payment_error: {
    title: "Erreur",
    description_1: "Le paiement a échoué, merci de réessayer.",
  },
  informations: {
    title: "Gestion du compte",
  },
  subscriptions: {
    title: "Gestion de l'abonnement",
    seats: "sièges",
    amount: "%{amount}€/an TTC",
    subscriptionStartedOn: "Abonnement débuté le %{date}",
    subscriptionTrialEndsOn: "En période d'essai jusqu'au %{date}",
    subscriptionNextRenewal: "Prochain renouvellement le %{date}",
    subscriptionEndsOn: "Fin de l'abonnement prévue le %{date}",
    paymentInformations: "Informations de paiement",
    cardDetails: "Carte %{brand} se terminant par %{lastFourDigits} (exp. %{expiration})",
    noPaymentMethod: "Aucun moyen de paiement renseigné",
    updatePaymentMethod: "Mettre à jour mon moyen de paiement",
    addPaymentMethod: "Ajouter un moyen de paiement",
    cancel: "Mettre fin à l'abonnement",
    aboutCancellation1: "Votre abonnement prendra fin à l'issue de la période de facturation en cours.",
    aboutCancellation2: "Vous pourrez ainsi continuer à profiter de vos services jusqu'au %{date}.",
    aboutCancellation3: "Notez par ailleurs que votre souscription pourra être réactivée à tout moment.",
    stopCancellation: "Réactiver l'abonnement",
    successfullyCanceled: "Demande d'arrêt de l'abonnement traîtée avec succès",
    successfullyReactivated: "Abonnement réactivé avec succès",
    quantitySuccessfullyUpdated: "Nombre de sièges ajusté avec succès",
    forbiddenDowngrade: "Impossible d'ajuster le nombre de sièges à une valeur inférieure au nombre de sièges occupés",
    amountOfSeats: "Nombre de sièges",
    perFiveSeats: "x 5 sièges",
    totalCalculatedSeats: "%{seats} sièges",
    totalCalculatedAmount: "soit %{amount}€/an TTC",
    seatsOccupiedAmount: "Il y a actuellement %{amount} siège(s) occupé(s) au sein de votre organisation.",
    adjustSeats: "Ajustez la valeur en fonction de vos besoins et de la taille actuelle de vos effectifs.",
    adjustSeatsQuantity: "Ajuster le nombre de sièges",
    paymentFailed: "Le moyen de paiement renseigné n'a pas pu être débité",
    upgradeSeats1:
      "Afin de bénéficier immédiatement des sièges supplémentaires, vous devez vous acquitter du montant annuel correspondant ajusté au prorata par rapport à la période de facturation en cours.",
    upgradeSeats2: "Ainsi, la somme de %{amount}€ TTC va être prélevée sur votre compte.",
    upgradeSeats3:
      "Le nouveau montant de votre abonnement annuel sera quant à lui toujours prélevé à l'échéance habituelle.",
    downgradeSeats1: "La suppression des sièges prend effet immédiatement.",
    downgradeSeats2:
      "Le montant déjà facturé pour ces sièges sur la période en cours sera déduit de votre prochaine facture.",
    resume: "Réactiver l'abonnement",
    aboutUnpaidResuming1:
      "Afin de réactiver votre abonnement, vous devez vous acquitter de la dernière facture impayée.",
    aboutUnpaidResuming2: "Assurez-vous au préalable d'avoir renseigné un moyen de paiement valide.",
    aboutUnpaidResuming3: "Les prochains prélèvements automatiques auront lieu à l'échéance annuelle habituelle.",
    aboutPausedResuming1:
      "Afin de réactiver votre abonnement, vous devez vous acquitter du montant pour l'année à venir.",
    aboutPausedResuming2: "Assurez-vous au préalable d'avoir renseigné un moyen de paiement valide.",
    aboutPausedResuming3: "Les prochains prélèvements automatiques auront lieu chaque année à la date du jour.",
    invoices: "Factures",
    noInvoice: "Aucune facture",
    referToOrganizationAdmin:
      "Rapprochez-vous d'un administrateur de votre organisation ou contactez le support MesVaccins.net afin de pouvoir gérer votre abonnement",
    referToSupport: "Contactez le support MesVaccins.net afin de pouvoir gérer votre abonnement",
    specialDiscount: "Tarif préférentiel",
    invalidPromotionCode: "Code promotionnel invalide",
    applyPromotionCode: "Appliquer le code promotionnel",
    discountSuccessfullyApplied: "Remise appliquée avec succès",
    appliedDiscount: "Remise de %{amount}€ appliquée (%{name})",
    amountWithDiscount: "%{amount}€/an TTC au lieu de",
    statuses: {
      trialing: "Période d'essai",
      active: "Actif",
      paused: "Inactif",
      past_due: "Impayé",
      canceled: "Annulé",
      unpaid: "Impayé",
    },
    invoiceStatuses: {
      open: "Impayée",
      paid: "Payée",
      uncollectible: "Annulée",
      void: "Annulée",
    },
  },
  sharing_requests: {
    status: "Aucun partage en attente",
    notification: "Vous serez notifié si le carnet d'un patient est partagé avec vous.",
    error: "Une erreur s'est produite",
    success: "Demande traitée avec succès",
    reject: "Refuser la demande de partage",
    reject_validation: "Êtes-vous sur de vouloir refuser cette demande de partage ?",
    back_to_health_records: "Accéder à ma patientèle",
  },
  patients: {
    add: "Ajouter un patient",
    name: "Nom",
    none: "Aucun patient",
    nothing: "Non renseigné",
    title: "Patients",
    tabs: {
      immunization: "Vaccins reçus",
      vaccinations_to_be_done: "Statut vaccinal",
      profile: "Profil santé",
      identity: "Identité",
      share: "Partage",
      event_log: "Journal d'événements",
    },
  },
  vaccines: {
    count: "%{smart_count} vaccination |||| %{smart_count} vaccinations",
    placeholder: "ex : Vaccin Grippe",
    distributionDate: "Disponible depuis le %{date}",
    title: "Vaccinations",
    error_messages: {
      error_1: "La vaccination ne peut pas être effectuée dans le futur ou avant la date de naissance du patient.",
      error_2: "La vaccination ne peut pas être effectuée après la date d'expiration de la vaccination.",
      error_3: "La méthode d'injection n'est pas correcte.",
      error_4: "Le lieu d'injection n'est pas correct.",
    },
    validate: "Valider le vaccin",
    validated: "Acte validé",
    not_validated: "Acte non validé",
    aProfessional: "un professionnel de santé",
    aTeam: "une équipe de soin",
    aTeamMember: "un professionnel de l'équipe %{teamInfo}",
    validatedUponExecutionBy: "Validé le %{date} car administré par %{certificator}",
    validatedUponProofBy: "Validé sur preuve le %{date} par %{certificator}",
    editableByProOnly: "Seul le professionnel de santé ayant réalisé cet acte peut le modifier",
    editableByTeamOnly: "Seul un professionnel de santé de l'équipe de soins ayant réalisé cet acte peut le modifier",
    confirmation: {
      do_validate: "J'ai realisé cet acte",
      not_validated: "Acte non validé",
      validate: "Je valide cet acte sur présentation d'un certificat ou du carnet de vaccination",
    },
    no_vaccinations: "Aucune vaccination enregistrée",
    no_vaccinations_sub: "Aucune vaccination présente sur ce carnet à la date d'arrêt du partage.",
    administration_date: "Date d'administration",
    administration: "Administré le %{date} à %{age}.",
    administration_booster: "Rappel administré le %{date} à %{age}.",
    administration_country: "Administré le %{date} à %{age} en %{country}.",
    administered_the: "Administré le ",
    administered_at: " à ",
    name: "Nom du vaccin",
    name_or_disease: "Nom du vaccin ou de la maladie",
    add_vaccination: "Ajouter une vaccination",
    add_vaccination_msg: "Ajoutez vos vaccinations pour faire le suivi de votre état vaccinal.",
    view_vaccination: "Consulter une vaccination",
    edit_vaccination: "Modifier une vaccination",
    delete_vaccination: "Supprimer la vaccination",
    delete_vaccination_validation: "Êtes-vous sur de vouloir supprimer cette vaccination ?",
    date_act: "Date de l'acte",
    act_date_greater_than_expiration_date: "A la date de l’acte, la date d’expiration est atteinte",
    expiration_date: "Date d'expiration",
    more: "Afficher plus de vaccins",
    serial_number: "Numéro de série",
    batch_number: "Numéro de lot",
    booster: "Rappel",
    injection_location: "Lieu d'administration",
    injection_path: "Voie d'administration",
    additional_information: "Données détaillées",
    printCertified: "Seuls les vaccins validés sont imprimables.",
    addVaccinationConfirmed: "Vaccination ajoutée avec succès",
    addVaccinations: {
      title: "Vaccins à enregistrer",
      no_vaccines_added: "Aucun vaccin ajouté, veuillez utiliser le formulaire",
    },
    addVaccinationType: {
      simple_entry: "Saisie simple",
      simple_entry_subtitle: "Saisie d un seul vaccin avec tous ses détails",
      multiple_entry: "Saisie multiple",
      multiple_entry_subtitle: "Saisie de plusieurs vaccins sans détails",
    },
    addSimilarVaccination: {
      title: "Vaccination similaire",
      details:
        "Une vaccination avec un vaccin similaire, à la même date, existe déjà pour ce patient. Êtes-vous sûr de vouloir l'ajouter ?",
    },
    vaccinesDistributed: "Vaccins disponibles à la date indiquée",
    otherVaccines: "Autres vaccins (disponibles à l’étranger, génériques ou anciens)",
    distributedSince: "Disponible depuis le %{distributionStart}",
    distributedBetween: "Disponible du %{distributionStart} au %{distributionEnd}",
    inlineForm: {
      addHistoricVaccination: "Saisie rapide des vaccins historiques",
      addNewVaccination: "Ajouter une nouvelle vaccination",
    },
    displayByDiseases: "Maladie",
    vaccineOfTheDay: "Saisie du vaccin du jour",
  },
  health_records: {
    success: "Carnet mis à jour avec succès",
    none_unshared: "Aucun carnet archivé",
    none_shared: "Aucun partage",
    share_button: "Partager ce carnet",
    share_button_2: "Partager le carnet",
    share_patients_and_pros: "Partager ce carnet avec le patient ou d'autres professionnels de santé",
    share_pros: "Partager ce carnet avec d'autres professionnels de santé",
    share_pro: "Partager avec un professionnel de santé",
    sharing_request_sent: "Demande de partage envoyée",
    patient_is_owner: "Le patient a accès à ce carnet via son compte",
    patient_is_not_owner: "Le patient n'a pas accès à ce carnet via son compte",
    cancel_invite: "Annuler l'invitation",
    cancel_invite_validation: "Êtes-vous sûr de vouloir annuler cette invitation ?",
    search_other_pro: "Nouveau partage",
    share: "Partager",
    copy_share_link: "Copier le lien de partage",
    copy_share_link_msg: "En cliquant ci-dessous, vous copierez dans votre press-papier le lien de partage.",
    copy_share_link_success: "Le lien a bien été copié dans le presse-papier.",
    pro_attestation: "J'atteste être autorisé par le patient à effectuer ce partage de carnet",
    history: "Retrouvez ici l'historique des carnets auxquels vous avez eu accès par le passé",
    create_or_access: "Créez des nouveaux patients ou accédez à leur carnet via le partage",
    remove_from_patients: "Retirer le carnet de la patientèle",
    remove_from_patients_validation: "Êtes-vous sur de vouloir retirer ce carnet de votre patientèle ?",
    remove_from_patients_validation_intro:
      "Le carnet retiré sera inséré dans la rubrique « Carnets archivés » dans laquelle il sera consultable mais non modifiable.",
    delete_the_health_record: "Supprimer le carnet",
    delete_the_health_record_validation: "Êtes-vous sur de vouloir supprimer ce carnet ?",
    delete_the_health_record_validation_intro:
      "Le carnet supprimé sera inséré dans la rubrique « Carnets archivés » dans laquelle il sera consultable mais non modifiable.",
    shared: "Carnet partagé avec le patient",
    shared_pro: "Carnet partagé avec un autre professionnel ou équipe de soin",
    non_shared: "Carnet non partagé avec le patient",
  },
  share: {
    browse_professional_directory:
      "Parcourez l'annuaire afin de sélectionner le professionnel de santé ou l'équipe de soins à qui vous souhaitez donner accès à ce carnet.",
    with_a_health_professional: "Avec un professionnel de santé",
    with_its_owner: "Avec le patient titulaire",
    share_the_health_record: "Partager le carnet",
    grant_ownership_disclaimer_1:
      "Vous êtes sur le point de proposer à un patient de devenir propriétaire de ce carnet.",
    link_copied: "Lien copié",
    generate_sharing_link: "Générer le lien",
    link_copied_to_clipboard: "Le lien a bien été copié dans le presse-papier",
    copy_sharing_link: "Copier le lien",
    copy_sharing_link_disclaimer_1:
      "En cliquant ci-dessous, vous copiez dans votre presse-papier un lien de transfert de propriété.",
    copy_sharing_link_disclaimer_2:
      "Une fois le lien copié, vous pourrez le coller dans un e-mail, un SMS, etc. et l'envoyer à la personne concernée.",
    copy_sharing_link_disclaimer_3:
      "Cette personne devra par mesure de sécurité renseigner la date de naissance du titulaire du carnet partagé afin d'y accéder.",
    share_with_team_disclaimer:
      "Un carnet partagé avec une équipe de soins sera accessible par tous les professionnels de santé qui la composent.",
    email_invalid: "Email invalide",
    email_sent: "Email envoyé avec succès à << %{email} >>",
    email_sharing: "Partager ce carnet directement en envoyant un e-mail :",
    email_sharing_description:
      "Préciser au patient que le lien n'est valide que 30 jours. S'il ne le reçoit pas immédiatement, il doit vérifier ses SPAMS.",
    email_grant_ownership: "Donnez la propriété du carnet directement en envoyant un mail :",
    share_link: "Copiez le lien ci-dessous et partagez-le à votre convenance :",
    share_link_share_description: "Ce lien peut être partagé par SMS ou e-mail.",
    grant_ownership_link: "Copiez le lien ci-dessous et partagez-le à votre convenance :",
    grant_ownership_link_description: "Ce lien peut être partagé par SMS ou e-mail.",
    qrcode_share: "Faites scanner ce QrCode pour partager le carnet :",
    qrcode_share_description: "Méthode rapide : à privilégier si le patient a un smartphone.",
    qrcode_grant_ownership: "Faites scanner ce QrCode par son futur propriétaire :",
    or: "OU",
    sms_grant_ownership: "Donnez la propriété du carnet directement en envoyant un sms :",
    sms_share: "Donnez accès au carnet en envoyant un sms :",
    other_share_methods: "Autres moyens de partage (QrCode, lien)",
    other_transfer_methods: "Autres moyens de transfert (QrCode, lien)",
    generate_pdf: "Imprimer le QrCode",
  },
  pdfView: {
    shareHealthRecord: "Ce carnet vous est partagé par %{user}",
    qrCode: "Ce QRCode est valide jusqu'au %{date}",
    vaccinesList: "Liste des vaccins du patient à ce jour (%{date})",
  },
  account: {
    email: "Email",
    password: "Mot de passe",
    mobile: "Numéro de téléphone mobile",
    infos:
      "Adresse e-mail et mot de passe sont vos identifiants de connexion. Le numéro de téléphone mobile permet une authentification à 2 facteurs, garant de la sécurité de votre compte. Aucun usage commercial ne sera fait de vos données personnelles.",
  },
  account_validation: {
    title: "Vérification de votre compte",
    rpps_verification: "Vérification de votre numéro RPPS",
    cps_button: "Me connecter avec ma carte CPS ou e-CPS",
    info: "Pour des raisons de sécurité, veuillez vous connecter avec votre carte CPS ou e-CPS.",
    manual_validation_option: "Je n'arrive pas à me connecter avec ma carte CPS",
    manual_validation_option_prompt:
      "Veuillez adresser votre preuve de profession (par exemple scan de votre carte CPS) à l'adresse suivante : ",
  },
  datamatrix: {
    dialogTitle: "Scanner un code datamatrix",
    preventionMethod: "Vaccin: ",
    serialNumber: "Numéro du série: ",
    batchNumber: "Numéro de lot: ",
    expirationDate: "Date d'expiration: ",
    scanCta: "Scanner le code Datamatrix",
    loading: "Interrogation des serveurs",
    noDeviceError: "Aucune caméra n'a été détectée.",
    notFoundToast:
      "Aucune correspondance de vaccin n’a été trouvée sur nos serveurs, merci de bien vouloir préciser le nom du vaccin.",
    scanError: "Il y a eu un problème lors de la lecture du code Datamatrix, veuillez réessayer.",
    serverError: "Il y a eu un problème lors de l'interrogation de la base de vaccins, veuillez réessayer.",
    successToast: "Le formulaire a bien été prérempli avec les résultats du scan.",
    camera: {
      tabTitle: "Camera",
      steps: {
        "1": "Mettre le code datamatrix dans le carré jaune",
        "2": "La recherche d'informations est automatique",
        "3": "La popup se fermera automatiquement et pré-remplira le formulaire",
      },
    },
    barCodeScanner: {
      tabTitle: "Lecteur de code barre",
      steps: {
        "1": "Scanner le code avec le lecteur de code barre",
        "2": "Le code apparaitra dans le champ texte",
        "3": "En cliquant sur le lecteur de code barre la popup se fermera automatiquement et pré-remplira le formulaire",
      },
    },
    inverseImage: "Inverser l'image de la caméra",
  },
  healthProfessional: {
    rppsNumber: "Numéro RPPS",
    noRppsNumber: "Aucun numéro RPPS",
  },
  team: {
    identificationNumber: "Numéro d'identification",
    name: "Nom de l'équipe",
    namePlaceholder: "ex: CHU de Bordeaux - Radiologie",
    address: "Adresse",
    city: "Ville",
    zipCode: "Code postal",
    phoneNumber: "Numéro de téléphone de contact",
    invite: "Inviter un nouveau membre",
    no_members: "Aucun membre dans l'équipe",
    member_list: "Liste des membres",
    member_list_remove: "Retirer un membre de l'équipe",
    member_list_remove_description: "Le membre ne pourra plus accéder aux carnets partagés avec l'équipe.",
    sendInvitation: {
      cta: "Envoyer l'invitation",
      success: "L'e-mail d'invitation a bien été envoyé",
      enterEmail: "Saisissez l'adresse e-mail du professionnel de santé à inviter dans votre équipe",
      description:
        "Un e-mail va être envoyé à ce destinataire. Il contient un lien d'invitation utilisable une seule fois et valable 7 jours.",
      noSeatsLeft:
        "Votre organisation ne dispose d'aucun siège libre (quantité ajustable depuis le menu 'Abonnement' réservé aux administrateurs)",
      seatsAmountLeft: "Votre organisation dispose de %{amount} siège(s) libre(s)",
    },
    role: {
      admin: "Administrateur",
      member: "Membre",
    },
    createPending: {
      success:
        "Votre demande d'ajout d'équipe a bien été prise en compte. Un administrateur de MesVaccins.net va traiter votre demande dans les plus brefs délais.",
      new: "Créer une équipe",
      description:
        "Le nom de votre équipe sera visible par le patient ou un autre professionnel dans l'annuaire intégré au carnet de vaccination numérique.",
    },
    acceptInvitation: {
      title: "Rejoindre une équipe",
      success: "Vous avez rejoint l'équipe %{teamName}.",
      error: "Une erreur est survenue. Veuillez contacter l'administrateur de l'équipe.",
    },
    pendingTeamActivation: "Équipe en attente d'activation",
    none: "Aucune équipe activée",
    add: "Créer une équipe",
    addTeamsForOrganization: "Ajoutez de nouvelles équipes au sein de votre organisation",
    contactAdminToAddTeams:
      "Contactez l'administrateur de votre structure ou le support MesVaccins.net afin de pouvoir ajouter des équipes",
    reactivateSubscriptionToAddTeam: "Réactivez votre abonnement afin de pouvoir ajouter des équipes",
    addPaymentMethodToAddTeam: "Renseignez un moyen de paiement valide afin de pouvoir ajouter des équipes",
    management: "Gestion des équipes",
    members: "membre |||| membres",
  },
  mentor: {
    title: "Mentor PRO : aide à la décision vaccinale",
  },
  verification: {
    cta: "Demander l'approbation du compte",
    alertMessage: "Votre adresse e-mail est maintenant validée",
    text1:
      "Votre inscription doit maintenant être approuvée par l'équipe de MesVaccins.net. Pour cela, nous devons vérifier votre profession.",
    text2:
      "Pour cela, veuillez nous adresser en pièce jointe au moins un des documents suivants : copie de la carte CPS, diplôme ou inscription a l'Ordre, en cliquant sur le bouton ci-dessous. ",
  },
  accountSettings: {
    visibilitySettings: {
      title: "Annuaire des professionnels de santé",
      tooltip: "L'affichage dans l'annuaire est requis pour recevoir des partages de carnets",
      display: "Afficher",
      hide: "Masquer",
    },
  },
  yup: {
    mixed: {
      default: "est invalide",
      required: "est obligatoire",
      oneOf: "doit avoir une des valeurs suivantes: %{values}",
      notOneOf: "ne doit pas avoir une des valeurs suivantes: %{values}",
      defined: "est obligatoire",
    },
    string: {
      length: "doit faire exactement %{length} caractères",
      min: "doit faire au moins %{min} caractères",
      max: "doit faire moins de %{max} caractères",
      matches: "est invalide et doit respecter le format requis",
      email: "doit être un email valide",
      url: "doit être une URL valide",
      uuid: "doit être un identifiant unique valide",
      trim: "ne doit pas commencer ou terminer par un espace",
      lowercase: "doit être en minuscule",
      uppercase: "doit être en majuscule",
    },
    number: {
      min: "doit être supérieur ou égal à %{min}",
      max: "doit être inférieur ou égale à %{max}",
      lessThan: "doit être inférieur à %{less}",
      moreThan: "doit être supérieur à %{more}",
      positive: "doit être un nombre positif",
      negative: "doit être un nombre négatif",
      integer: "doit être un nombre entier",
    },
    date: {
      min: "doit être le %{min} ou après",
      max: "doit être le %{max} ou avant",
      invalid: "n'est pas une date valide",
    },
    boolean: {
      isValue: "doit avoir la valeur %{value}",
    },
    object: {
      noUnknown: "doit contenir les clés: %{unknown}",
    },
    array: {
      min: "doit contenir au moins %{min} éléments",
      max: "doit contenir au maximum %{max} éléments",
      length: "doit contenir %{length} éléments",
    },
  },
  countries: {
    AFG: "Afghanistan",
    ALB: "Albanie",
    DZA: "Algérie",
    ASM: "Samoa américaines",
    AND: "Andorre",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctique",
    ATG: "Antigua-et-Barbuda",
    ARG: "Argentine",
    ARM: "Arménie",
    ABW: "Aruba",
    AUS: "Australie",
    AUT: "L'Autriche",
    AZE: "Azerbaïdjan",
    BHS: "Bahamas (les)",
    BHR: "Bahreïn",
    BGD: "Bangladesh",
    BRB: "Barbade",
    BLR: "Biélorussie",
    BEL: "Belgique",
    BLZ: "Bélize",
    BEN: "Bénin",
    BMU: "Bermudes",
    BTN: "Bhoutan",
    BOL: "Bolivie (État plurinational de)",
    BES: "Bonaire, Saint Eustache et Saba",
    BIH: "Bosnie Herzégovine",
    BWA: "Botswana",
    BVT: "Île Bouvet",
    BRA: "Brésil",
    IOT: "Territoire britannique de l'océan Indien (le)",
    BRN: "Brunei Darussalam",
    BGR: "Bulgarie",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    CPV: "Cap-Vert",
    KHM: "Cambodge",
    CMR: "Cameroun",
    CAN: "Canada",
    CYM: "Îles Caïmans (les)",
    CAF: "République centrafricaine (la)",
    TCD: "Tchad",
    CHL: "Chili",
    CHN: "Chine",
    CXR: "L'île de noël",
    CCK: "Îles Cocos (Keeling) (les)",
    COL: "Colombie",
    COM: "Comores (les)",
    COD: "Congo (République démocratique du)",
    COG: "Congo (le)",
    COK: "Îles Cook (les)",
    CRI: "Costa Rica",
    HRV: "Croatie",
    CUB: "Cuba",
    CUW: "Curacao",
    CYP: "Chypre",
    CZE: "Tchéquie",
    CIV: "Côte d'Ivoire",
    DNK: "Danemark",
    DJI: "Djibouti",
    DMA: "Dominique",
    DOM: "République dominicaine (la)",
    ECU: "Equateur",
    EGY: "Egypte",
    SLV: "Le Salvador",
    GNQ: "Guinée Équatoriale",
    ERI: "Erythrée",
    EST: "Estonie",
    SWZ: "Eswatini",
    ETH: "Ethiopie",
    FLK: "Îles Falkland (les) [Malouines]",
    FRO: "Îles Féroé (les)",
    FJI: "Fidji",
    FIN: "Finlande",
    FRA: "France",
    GUF: "Guyane Française",
    PYF: "Polynésie française",
    ATF: "Terres australes françaises (les)",
    GAB: "Gabon",
    GMB: "Gambie (la)",
    GEO: "Géorgie",
    DEU: "Allemagne",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Grèce",
    GRL: "Groenland",
    GRD: "Grenade",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernesey",
    GIN: "Guinée",
    GNB: "Guinée-Bissau",
    GUY: "Guyana",
    HTI: "Haïti",
    HMD: "Île Heard et îles McDonald",
    VAT: "Saint-Siège (le)",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hongrie",
    ISL: "Islande",
    IND: "Inde",
    IDN: "Indonésie",
    IRN: "Iran (République islamique d)",
    IRQ: "Irak",
    IRL: "Irlande",
    IMN: "Île de Man",
    ISR: "Israël",
    ITA: "Italie",
    JAM: "Jamaïque",
    JPN: "Japon",
    JEY: "Jersey",
    JOR: "Jordan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Corée (République populaire démocratique de)",
    KOR: "Corée (la République de)",
    KWT: "Koweit",
    KGZ: "Kirghizistan",
    LAO: "Laos / République Démocratique Populaire Lao",
    LVA: "Lettonie",
    LBN: "Liban",
    LSO: "Lesotho",
    LBR: "Libéria",
    LBY: "Libye",
    LIE: "Liechtenstein",
    LTU: "Lituanie",
    LUX: "Luxembourg",
    MAC: "Macao",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaisie",
    MDV: "Maldives",
    MLI: " Mali ",
    MLT: "Malte",
    MHL: "Îles Marshall (les)",
    MTQ: "Martinique",
    MRT: "Mauritanie",
    MUS: "Maurice",
    MYT: "Mayotte",
    MEX: "Mexique",
    FSM: "Micronésie (États fédérés de)",
    MDA: "Moldavie (République de)",
    MCO: "Monaco",
    MNG: "Mongolie",
    MNE: "Monténégro",
    MSR: "Montserrat",
    MAR: "Maroc",
    MOZ: "Mozambique",
    MMR: "Myanmar (Birmanie)",
    NAM: "Namibie",
    NRU: "Nauru",
    NPL: "Népal",
    NLD: "Pays-Bas (les)",
    NCL: "Nouvelle Calédonie",
    NZL: "Nouvelle-Zélande",
    NIC: "Nicaragua",
    NER: "Niger (le)",
    NGA: "Nigeria",
    NIU: "Niué",
    NFK: "L'ile de Norfolk",
    MNP: "Îles Mariannes du Nord (les)",
    NOR: "Norvège",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palaos",
    PSE: "Palestine, État de",
    PAN: "Panama",
    PNG: "Papouasie Nouvelle Guinée",
    PRY: "Paraguay",
    PER: "Pérou",
    PHL: "Philippines (les)",
    PCN: "Pitcairn",
    POL: "Pologne",
    PRT: "Le Portugal",
    PRI: "Porto Rico",
    QAT: "Qatar",
    MKD: "République de Macédoine du Nord",
    ROU: "Roumanie",
    RUS: "Fédération de Russie (la)",
    RWA: "Rwanda",
    REU: "La Réunion",
    BLM: "Saint Barthélemy",
    SHN: "Sainte Hélène, Ascension et Tristan da Cunha",
    KNA: "Saint-Christophe-et-Niévès",
    LCA: "Sainte-Lucie",
    MAF: "Saint Martin (partie française)",
    SPM: "Saint-Pierre-et-Miquelon",
    VCT: "Saint-Vincent-et-les-Grenadines",
    WSM: "Samoa",
    SMR: "Saint Marin",
    STP: "Sao Tomé et Principe",
    SAU: "Arabie Saoudite",
    SEN: "Sénégal",
    SRB: "Serbie",
    SYC: "Les Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapour",
    SXM: "Sint Maarten (partie hollandaise)",
    SVK: "Slovaquie",
    SVN: "Slovénie",
    SLB: "Îles Salomon",
    SOM: "Somalie",
    ZAF: "Afrique du Sud",
    SGS: "Géorgie du Sud et îles Sandwich du Sud",
    SSD: "Soudan du sud",
    ESP: "Espagne",
    LKA: "Sri Lanka",
    SDN: "Soudan (le)",
    SUR: "Surinam",
    SJM: "Svalbard et Jan Mayen",
    SWE: "Suède",
    CHE: "Suisse",
    SYR: "République arabe syrienne",
    TWN: "Taiwan, Province de Chine)",
    TJK: "Tadjikistan",
    TZA: "Tanzanie, République-Unie de",
    THA: "Thaïlande",
    TLS: "Timor Oriental",
    TGO: "Togo",
    TKL: "Tokélaou",
    TON: "Tonga",
    TTO: "Trinité-et-Tobago",
    TUN: "Tunisie",
    TUR: "Turquie",
    TKM: "Turkménistan",
    TCA: "Îles Turques et Caïques (les)",
    TUV: "Tuvalu",
    UGA: "Ouganda",
    UKR: "Ukraine",
    ARE: "Emirats Arabes Unis (les)",
    GBR: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    UMI: "Îles mineures éloignées des États-Unis (les)",
    USA: "États-Unis d'Amérique (les)",
    URY: "Uruguay",
    UZB: "Ouzbékistan",
    VUT: "Vanuatu",
    VEN: "Venezuela (République bolivarienne du)",
    VNM: "Vietnam",
    VGB: "Îles Vierges (britanniques)",
    VIR: "Îles Vierges (États-Unis)",
    WLF: "Wallis et Futuna",
    ESH: "Sahara occidental",
    YEM: "Yémen",
    ZMB: "Zambie",
    ZWE: "Zimbabwe",
    ALA: "Iles Aland",
  },
};

export type TranslationObject = typeof fr;
