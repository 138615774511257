import { VaccinationActApi as ProVaccinationActApi, VaccinationAct as ProVaccinationAct } from "@syadem/kairos-pro-js";
import {
  VaccinationActApi as TeamVaccinationActApi,
  VaccinationAct as TeamVaccinationAct,
} from "@syadem/kairos-team-js";
import { AppStore } from "../store";
import { unionBy } from "lodash-es";
import { Service } from "./service";
import { ActionResult } from "../actionResult";

interface Payload {
  organizationId: string | null;
  teamId: string | null;
  healthRecordId: string;
  vaccinationActId: string;
  certificationMethod: string;
}

export class CertifyVaccinationActService implements Service<Payload, ProVaccinationAct | TeamVaccinationAct> {
  constructor(
    private readonly proVaccinationActApi: ProVaccinationActApi,
    private readonly teamVaccinationActApi: TeamVaccinationActApi,
    private readonly store: AppStore,
  ) {}

  async call({
    organizationId,
    teamId,
    healthRecordId,
    vaccinationActId,
    certificationMethod,
  }: Payload): Promise<ActionResult<ProVaccinationAct | TeamVaccinationAct>> {
    let vaccinationAct: ProVaccinationAct | TeamVaccinationAct;
    if (organizationId && teamId) {
      vaccinationAct = (
        await this.teamVaccinationActApi.certifyVaccinationAct(
          organizationId,
          teamId,
          healthRecordId,
          vaccinationActId,
          {
            vaccinationAct: { certificationMethod },
          },
        )
      ).vaccinationAct;
    } else {
      vaccinationAct = (
        await this.proVaccinationActApi.certifyVaccinationAct(healthRecordId, vaccinationActId, {
          vaccinationAct: { certificationMethod: certificationMethod },
        })
      ).vaccinationAct;
    }

    this.certifyVaccinationActInStore(healthRecordId, vaccinationAct);

    return {
      type: "success",
      value: vaccinationAct,
      message: "certification.successMessage",
    };
  }

  private certifyVaccinationActInStore(healthRecordId: string, vaccinationAct: ProVaccinationAct | TeamVaccinationAct) {
    this.store.setState((state) =>
      state.healthRecords && state.healthRecords[healthRecordId]
        ? {
            healthRecords: {
              ...state.healthRecords,
              [healthRecordId]: {
                ...state.healthRecords[healthRecordId],
                vaccinationActs: unionBy(
                  [vaccinationAct],
                  state.healthRecords[healthRecordId].vaccinationActs,
                  (v) => v.id,
                ),
              },
            },
          }
        : state,
    );
  }
}
