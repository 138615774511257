import { useCallback, useEffect, useRef, useState } from "react";
import { useQueries } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";
import { useHealthRecord } from "../../store";

export function useFetchHealthRecordDocuments(id: string): {
  isLoading: boolean;
  error?: Response | Error;
  refetch: () => void;
} {
  const { organizationId, teamId } = useAppContext();
  const healthRecord = useHealthRecord(id);

  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const queries = useQueries();
  const fetchingRef = useRef(false);

  const fetchDocuments = useCallback(async () => {
    try {
      if (!healthRecord?.id) return;

      fetchingRef.current = true;
      setIsLoading(true);
      setError(undefined);
      if (organizationId && teamId) {
        await queries.teamDocumentQuery.call({ organizationId, teamId, healthRecordId: id });
      } else {
        await queries.proDocumentQuery.call(id);
      }
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        setError(error);
      } else {
        throw error;
      }
    } finally {
      fetchingRef.current = false;
      setIsLoading(false);
    }
  }, [id, organizationId, teamId, healthRecord?.id, queries.proDocumentQuery, queries.teamDocumentQuery]);

  useEffect(() => {
    if (fetchingRef.current) return;
    fetchDocuments();
  }, [fetchDocuments]);

  return {
    error,
    isLoading,
    refetch: fetchDocuments,
  };
}
